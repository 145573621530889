import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";
import SimulationResultMessages from "pages/SimulationResult/SimulationResultMessages";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
}

const IrradianceContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();
  const unitePeriodIrrOptions = useMemo(() => {
    return irrUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [irrUnites]);
  const [selectedPeriodIrrUnite, setSelectedPeriodIrrUnite] = useState<
    "Fraction" | "Daily PAR"
  >("Fraction");
  const [inactiveKeysPeriodIrr, setInactiveKeyPeriodIrr] = useState(
    Array<string>()
  );

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const period_irr_points = useMemo(() => {
    if (result && selectedPeriodIrrUnite) {
      return result.simulation.crop_for_water_balance.periods.map(
        (period: any, index: number) => {
          const values = getValueInCropPeriod(period);


          const period_irr_zt = values.reduce(
            (acc: number, item: any) => acc + item.irr_zt,
            0
          );
          const period_irr_under_panel = values.reduce(
            (acc: number, item: any) => acc + item.irr_under_panel,
            0
          );
          const period_irr_between_panel = values.reduce(
            (acc: number, item: any) => acc + item.irr_between_panel,
            0
          );
          const period_irr_agri_pv = values.reduce(
            (acc: number, item: any) => acc + item.irr_agri_pv,
            0
          );

          return {
            name: period.name,
            witness_zone:
              Math.round(
                (selectedPeriodIrrUnite == "Daily PAR"
                  ?
                  ((1 / 2.1) * period_irr_zt) /
                  ((new Date(period.end_date).getTime() -
                    new Date(period.start_date).getTime()) /
                    (1000 * 3600 * 24))
                  : (period_irr_zt * 100) / period_irr_zt) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedPeriodIrrUnite == "Daily PAR"
                  ? ((1 / 2.1) * period_irr_under_panel) /
                  ((new Date(period.end_date).getTime() -
                    new Date(period.start_date).getTime()) /
                    (1000 * 3600 * 24)
                  )
                  : (period_irr_under_panel * 100) / period_irr_zt) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedPeriodIrrUnite == "Daily PAR"
                  ?
                  ((1 / 2.1) * period_irr_between_panel) /
                  ((new Date(period.end_date).getTime() -
                    new Date(period.start_date).getTime()) /
                    (1000 * 3600 * 24)
                  )
                  : (period_irr_between_panel * 100) / period_irr_zt) * 100
              ) / 100,
            agriPV:
              Math.round(
                (selectedPeriodIrrUnite == "Daily PAR"
                  ?
                  ((1 / 2.1) * period_irr_agri_pv) /
                  ((new Date(period.end_date).getTime() -
                    new Date(period.start_date).getTime()) /
                    (1000 * 3600 * 24))
                  : (period_irr_agri_pv * 100) / period_irr_zt),
              ),
            heterogeneity: Math.abs((period_irr_under_panel - period_irr_between_panel) / (2 * period_irr_agri_pv)) * 100
            // heterogeneity: Math.abs(Math.round(()))
          };
        }
      );
    }
    return null;
  }, [result, selectedPeriodIrrUnite]);

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.uniteSelector}>
            <Select
              options={unitePeriodIrrOptions}
              value={unitePeriodIrrOptions.find(
                (item) => item.value == selectedPeriodIrrUnite
              )}
              onChange={(e: any) => {
                setSelectedPeriodIrrUnite(e.value);
              }}
              isSearchable={false}
              menuHeight={400}
            />
          </div>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)}
            </div>
          </TooltipMUI>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  period_irr_points,
                  `${result.simulation.name} Irradiance per Stage (${selectedPeriodIrrUnite})`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>

        <div id={intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={period_irr_points}
              width={500}
              height={700}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"name"}
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis
                // domain={[0, 100]}
                interval={0}
                yAxisId={"left"}
                orientation="left"
              >
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"left"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    selectedPeriodIrrUnite == "Fraction"
                      ? SimulationResultBodyMessages.irradianceFraction
                      : SimulationResultBodyMessages.irrandianceDailyPAR
                  )}
                />
              </YAxis>
              <YAxis yAxisId="right" orientation="right" interval={0}>
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideRight"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.heterogeneityTh
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodIrr.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodIrr([
                      ...inactiveKeysPeriodIrr,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodIrr;
                    temp.splice(
                      inactiveKeysPeriodIrr.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodIrr([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"witness_zone"}
                name="Control Zone"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "witness_zone"
                  )
                    ? true
                    : false
                }
                unit={selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"}
              />
              <Bar
                type="monotone"
                dataKey={"under_panel"}
                name="Under Panels"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "under_panel"
                  )
                    ? true
                    : false
                }
                unit={selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"}
              />
              <Bar
                type="monotone"
                dataKey={"between_panel"}
                name="Between Panels"
                yAxisId="left"
                fill={"#6666AA"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "between_panel"
                  )
                    ? true
                    : false
                }
                unit={selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"}
              />
              <Bar
                type="monotone"
                dataKey={"agriPV"}
                name="AgriPV Zone"
                yAxisId="left"
                fill={"#444444"}
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "agriPV"
                  )
                    ? true
                    : false
                }
                unit={selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"}
              />
              <Line
                type="linear"
                dataKey={"heterogeneity"}
                yAxisId="right"
                hide={
                  inactiveKeysPeriodIrr.find(
                    (inactiveKey) => inactiveKey == "heterogeneity"
                  )
                    ? true
                    : false
                }
                dot={({ cx, cy }) => {
                  return (
                    <circle
                      key={cx.toString()}
                      cx={cx}
                      cy={cy}
                      r={6}
                      fill="#66AAAA"
                    />
                  );
                }}
                unit={"%"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            SimulationResultBodyMessages.hintChartToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng(
              "Table " +
              intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
        className={styled.dataTable}
        id={
          "Table " +
          intl.formatMessage(SimulationResultBodyMessages.irrPerPeriod)
        }
      >
        <thead>
          <tr>
            <th>
              <FormattedMessage {...CropCreateMessages.stageName} />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.irradianceRefZone}
                values={{
                  unit: selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²",
                }}
              />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.betweenPanels}
                values={{
                  unit: selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²",
                }}
              />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.underPanels}
                values={{
                  unit: selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²",
                }}
              />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.agrilPV}
                values={{
                  unit: selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²",
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {period_irr_points.map((item: any) => (
            <tr key={`tableIndex${item.name}`}>
              <td>{item.name}</td>
              <td>{item.witness_zone}</td>
              <td>{item.under_panel}</td>
              <td>{item.between_panel}</td>
              <td>{item.agriPV}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IrradianceContent;
