import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import styled from "./styled.module.scss";
import { ReactComponent as Arrow } from "assets/icons/arrow-select.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import Label from "components/common/label/Label";
import Accordion from "components/Accordion/Accordion";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import clsx from "clsx";
import parametersInterestMessages from "components/WaterBalanceParametersInterest/WaterBalanceParametersInterestMessages";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  IInterestNameVariantsWaterBalance,
  IInterestParamsWaterBalanceItem,
  useWaterBalanceParamsList
} from "components/WaterBalanceParametersInterest/useParamsList";
import useFuse from "hooks/useFuse";
import Input from "components/common/input/Input";
import useDebounceState from "hooks/useDebounceState";
import CheckBox from "components/common/checkBox/CheckBox";
import { usePopper } from "react-popper";
import { SpanError } from "components/common/span-error/SpanError";

interface IWaterBalanceParametersInterest {
  label?: string;
  values: IInterestNameVariantsWaterBalance[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setTouched: () => void;
  errorMessage?: string;
}

const WaterBalanceParametersInterest = ({
  label,
  values,
  onChange,
  setTouched,
  errorMessage,
}: IWaterBalanceParametersInterest) => {
  const intl = useIntl();
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const params = useWaterBalanceParamsList();
  const [search, debounceSearch, setSearch] = useDebounceState("");

  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const { attributes } = usePopper(
    referenceElement.current,
    popperElement.current
  );

  const searchResults = useFuse<IInterestParamsWaterBalanceItem>(params, debounceSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ["label"],
  });

  const { datasets } = useMemo(() => {
    const datasets = searchResults.filter(
      ({ category, name }) => category === "datasets" && name != 'production_dataset_id'
    );

    return {
      datasets,
    };
  }, [searchResults]);

  const onToggle = () => {
    if (isOpen) setTouched();
    setIsOpen((prev) => !prev);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useOnClickOutside(menuRef, onToggle);

  return (
    <div className={styled.container} {...(isOpen && { ref: menuRef })}>
      {!!label && <Label>{label}</Label>}

      <div
        className={clsx(styled.input, {
          [styled.errorBorder]: !!errorMessage,
        })}
        ref={referenceElement}
        onClick={onToggle}
      >
        <span>
          <FormattedMessage
            {...parametersInterestMessages.selected}
            values={{
              value: values.length,
            }}
          />
        </span>
        <Arrow />
      </div>

      <SpanError errorMessage={errorMessage} />

      <div
        className={clsx(styled.menu, {
          [styled.open]: isOpen,
        })}
        ref={popperElement}
        {...attributes.popper}
      >
        <Input
          value={search}
          onChange={onSearchChange}
          className={styled.search}
          placeholder={intl.formatMessage(parametersInterestMessages.search)}
          beforeIcon={<Search />}
        />
        <Accordion title={intl.formatMessage(commonMessages.datasets)}>
          {datasets.map((item) => (
            <CheckBox
              key={item.name}
              label={item.label}
              onChange={onChange}
              value={item.name}
              checked={values.includes(item.name)}
            />
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default WaterBalanceParametersInterest;
