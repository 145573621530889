import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import chartToCsv from "utils/chartToCsv";
import BatchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import chartToPng from "utils/chartToPng";

class AnalysisContentProps {
  result: any;
}

const AnalysisContent = ({ result }: AnalysisContentProps) => {
  const intl = useIntl();

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const calculateRainPerPeriod = (period: any) => {
    if (!result.simulation.ETP_dataset)
      return "Simulation Doesn't has ETP dataset.";
    else {
      const start_period = period.start_date;
      const end_period = period.end_date;

      const precipitation = result.simulation.ETP_dataset.dataset_data.reduce(
        (acc: number, val: any) => {
          if (
            start_period <= val.date &&
            end_period >= val.date &&
            val.month === null
          )
            acc += val.precipitation;
          return acc;
        },
        0
      );
      return Math.round(precipitation);
    }
  };

  const calculateWaterConsumptionSimulation = (
    result_daily_data: any,
    period: any
  ) => {
    const start_period = new Date(period.start_date);
    const end_period = new Date(period.end_date);

    const sorted_tag = result_daily_data.filter(
      (data: any) =>
        start_period <= new Date(data.date) && new Date(data.date) <= end_period
    );
    const wc_agri_pv = sorted_tag.reduce(
      (acc: number, { wc_agri_pv }: any) => acc + wc_agri_pv,
      0
    );
    // return Math.round(wc_agri_pv)
    return Math.round(wc_agri_pv);
  };

  const calculateMeanOfTableValues = (type: string) => {
    let totalValue = 0;
    let totalDays = 0;
    for (let index = 0; index < result.simulation.crop_for_water_balance.periods.length; index++) {
      const period = result.simulation.crop_for_water_balance.periods[index];
      const period_length =
        (new Date(period.end_date).getTime() -
          new Date(period.start_date).getTime()) /
        (1000 * 3600 * 24);


      totalValue += period_length * tableValues[index][type];
      totalDays += period_length;
    }

    if (type == 'IRR_FRACTION')
      return Math.round((totalValue / totalDays) * 10) / 10;
    else
      return Math.round(totalValue / totalDays);

  };

  const getRowBackgroundColor = (value: number, type: string, period?: any) => {
    const sortedData = [...tableValues.map((val: any) => val[type])].sort(
      (a, b) => a - b
    );
    var median,
      min,
      max = 0;
    if (type == "STRESS_HYDRIQUE_ZT" || type == "STRESS_HYDRIQUE_AGRIPV") {
      const number_of_days =
        (new Date(period.end_date).getTime() -
          new Date(period.start_date).getTime()) /
        (1000 * 3600 * 24);

      median = Math.floor(number_of_days / 2);
      min = 0;
      max = number_of_days;
    } else {
      const middle = Math.floor(sortedData.length / 2);
      median =
        sortedData.length % 2 === 0
          ? (sortedData[middle - 1] + sortedData[middle]) / 2
          : sortedData[middle];

      min = sortedData[0];
      max = sortedData[sortedData.length - 1];
    }

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (type == "WATER_CONSUMPTION" || type == "RAIN") {
      return "white";
    }

    if (type == "STRESS_HYDRIQUE_ZT" || type == "STRESS_HYDRIQUE_AGRIPV")
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const tableValues = useMemo(() => {
    if (
      !result ||
      !result.result_data.length ||
      !result.result_data[0] ||
      !result.result_data[0].result_daily_data ||
      !result.water_balance_analysis ||
      !result.water_balance_analysis.length
    )
      return null;
    return result.simulation.crop_for_water_balance.periods.map(
      (period: any, index: number) => {
        const values = getValueInCropPeriod(period);
        if (!values.length)
          return {
            IRR_FRACTION: 0,
            DAILY_PAR: 0,
            WATER_CONSUMPTION: 0,
            STRESS_HYDRIQUE_ZT: 0,
            STRESS_HYDRIQUE_AGRIPV: 0,
            RAIN: 0,
          };
        return {
          IRR_FRACTION:
            Math.round(
              (((values.reduce(
                (acc: number, data: any) => acc + data.irr_agri_pv,
                0
              ) /
                values.length) *
                100) /
                (values.reduce(
                  (acc: number, data: any) => acc + data.irr_zt,
                  0
                ) /
                  values.length)) *
              10
            ) / 10,
          DAILY_PAR: Math.round(
            values.reduce(
              (acc: number, data: any) => acc + (1 / 2.1) * data.irr_agri_pv,
              0
            ) / values.length
          ),
          WATER_CONSUMPTION: calculateWaterConsumptionSimulation(
            result.result_data[0].result_daily_data,
            period
          ),
          STRESS_HYDRIQUE_ZT: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).control_zone,
          STRESS_HYDRIQUE_AGRIPV: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).agriPV,
          RAIN: calculateRainPerPeriod(period),
        };
      }
    );
  }, [result]);


  return <div className="list">
    <div className={styled.downloadContainer}>
      <TooltipMUI
        title={intl.formatMessage(
          WaterBalanceResultBodyMessages.hintTableToPng
        )}
      >
        <div
          className={styled.chartToPngButton}
          onClick={chartToPng("Table")}
        >
          <Icon
            name={ICON_NAMES_ENUM.download_image}
            className={styled.file__icon}
          />
        </div>
      </TooltipMUI>
      <TooltipMUI
        title={intl.formatMessage(
          WaterBalanceResultBodyMessages.hintTableToCsv
        )}
      >
        <div
          className={styled.chartToPngButton}
          onClick={() => {
            const values = tableValues.map((data: any, index: number) => {
              const val: any = {};
              val[intl.formatMessage(SimulationResultBodyMessages.periodSimulation)] = result.simulation.crop_for_water_balance.periods[index].name
              val[intl.formatMessage(BatchesResultMessages.irradianceFraction) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = data.IRR_FRACTION
              val[intl.formatMessage(BatchesResultMessages.meanDailyPAR) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = data.DAILY_PAR
              val[intl.formatMessage(!result.simulation.water_balance.irrigation ? BatchesResultMessages.waterStress : BatchesResultMessages.irrigationValue) + " " + intl.formatMessage(BatchesResultMessages.waterStressSubTitleInZT)] = data.STRESS_HYDRIQUE_ZT
              val[intl.formatMessage(!result.simulation.water_balance.irrigation ? BatchesResultMessages.waterStress : BatchesResultMessages.irrigationValue) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = data.STRESS_HYDRIQUE_AGRIPV
              val[intl.formatMessage(BatchesResultMessages.waterConsumption) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = data.WATER_CONSUMPTION
              val[intl.formatMessage(BatchesResultMessages.precipitation)] = data.RAIN

              return val;
            })

            var obj: any = {}
            obj[intl.formatMessage(SimulationResultBodyMessages.periodSimulation)] = intl.formatMessage(SimulationResultBodyMessages.mean);
            obj[intl.formatMessage(BatchesResultMessages.irradianceFraction) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = calculateMeanOfTableValues("IRR_FRACTION")
            obj[intl.formatMessage(BatchesResultMessages.meanDailyPAR) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = calculateMeanOfTableValues("DAILY_PAR")
            obj[intl.formatMessage(!result.simulation.water_balance.irrigation ? BatchesResultMessages.waterStress : BatchesResultMessages.irrigationValue) + " " + intl.formatMessage(BatchesResultMessages.waterStressSubTitleInZT)] = calculateMeanOfTableValues("STRESS_HYDRIQUE_ZT")
            obj[intl.formatMessage(!result.simulation.water_balance.irrigation ? BatchesResultMessages.waterStress : BatchesResultMessages.irrigationValue) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = calculateMeanOfTableValues("STRESS_HYDRIQUE_AGRIPV")
            obj[intl.formatMessage(BatchesResultMessages.waterConsumption) + " " + intl.formatMessage(BatchesResultMessages.ETPAgriPVZoneSubTitle)] = calculateMeanOfTableValues("WATER_CONSUMPTION")
            obj[intl.formatMessage(BatchesResultMessages.precipitation)] = calculateMeanOfTableValues("RAIN")
            chartToCsv([...values, obj], `${result.simulation.name} table data`)();
            chartToCsv([...values], `${result.simulation.name} table data`)();
          }}
        >
          <Icon
            name={ICON_NAMES_ENUM.download_file}
            className={styled.file__icon}
          />
        </div>
      </TooltipMUI>
    </div>
    <table className={styled.dataTable} id="Table">
      <thead>
        <tr>
          <th>
            <FormattedMessage {...SimulationResultBodyMessages.periodSimulation} />
          </th>

          <th>
            <TooltipMUI
              title={intl.formatMessage(
                BatchesResultMessages.irradianceFractionHelp
              )}
            >
              <div>
                <div className={`${styled.flex} ${styled.justifyCenter}`}>
                  <FormattedMessage
                    {...BatchesResultMessages.irradianceFraction}
                  />
                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
                <div className={styled.subTitle}>
                  <FormattedMessage
                    {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                  />
                </div>
              </div>
            </TooltipMUI>
          </th>
          <th>
            <TooltipMUI
              title={intl.formatMessage(
                BatchesResultMessages.meanDailyPARHelp
              )}
            >
              <div>
                <div className={`${styled.flex} ${styled.justifyCenter}`}>
                  <FormattedMessage
                    {...BatchesResultMessages.meanDailyPAR}
                  />

                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
                <div className={styled.subTitle}>
                  <FormattedMessage
                    {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                  />
                </div>
              </div>
            </TooltipMUI>
          </th>
          <th>
            <div>
              {!result.simulation.water_balance.irrigation ? (
                <FormattedMessage
                  {...BatchesResultMessages.waterStress}
                />
              ) : (
                <FormattedMessage
                  {...BatchesResultMessages.irrigationValue}
                />
              )}
              <div className={styled.subTitle}>
                <FormattedMessage
                  {...BatchesResultMessages.waterStressSubTitleInZT}
                />
              </div>
            </div>
          </th>
          <th>
            <div>
              {!result.simulation.water_balance.irrigation ? (
                <FormattedMessage
                  {...BatchesResultMessages.waterStress}
                />
              ) : (
                <FormattedMessage
                  {...BatchesResultMessages.irrigationValue}
                />
              )}
              <div className={styled.subTitle}>
                <FormattedMessage
                  {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                />
              </div>
            </div>
          </th>
          <th>
            <TooltipMUI
              title={intl.formatMessage(
                BatchesResultMessages.waterDeficitHelp
              )}
            >
              <div>
                <div className={`${styled.flex} ${styled.justifyCenter}`}>
                  <FormattedMessage
                    {...BatchesResultMessages.waterConsumption}
                  />
                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
                <div className={styled.subTitle}>
                  <FormattedMessage
                    {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                  />
                </div>
              </div>
            </TooltipMUI>
          </th>


          <th>
            <div>
              <FormattedMessage
                {...BatchesResultMessages.precipitation}
              />
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        {result.simulation.crop_for_water_balance.periods.map(
          (period: any, index: number) => (
            <tr key={period.id}>
              <td>
                <span>{period.name}</span>
              </td>
              <td
                style={{
                  backgroundColor: getRowBackgroundColor(
                    tableValues[index].IRR_FRACTION,
                    "IRR_FRACTION"
                  ),
                }}
              >
                {
                  // IRR FRACTION
                  tableValues[index].IRR_FRACTION
                }
              </td>
              <td
                style={{
                  backgroundColor: getRowBackgroundColor(
                    tableValues[index].DAILY_PAR,
                    "DAILY_PAR"
                  ),
                }}
              >
                {
                  // DAILY PAR
                  tableValues[index].DAILY_PAR
                }
              </td>
              <td
                style={{
                  backgroundColor: getRowBackgroundColor(
                    tableValues[index].STRESS_HYDRIQUE_ZT,
                    "STRESS_HYDRIQUE_ZT",
                    period
                  ),
                }}
              >
                {tableValues[index].STRESS_HYDRIQUE_ZT}
              </td>
              <td
                style={{
                  backgroundColor: getRowBackgroundColor(
                    tableValues[index].STRESS_HYDRIQUE_AGRIPV,
                    "STRESS_HYDRIQUE_AGRIPV",
                    period
                  ),
                }}
              >
                {tableValues[index].STRESS_HYDRIQUE_AGRIPV}
              </td>
              <td>{tableValues[index].WATER_CONSUMPTION}</td>
              <td>{tableValues[index].RAIN}</td>
            </tr>
          )
        )}
        <tr className={styled.mean}>
          <td>
            <span> <FormattedMessage
              {...SimulationResultBodyMessages.mean}
            /></span>
          </td>
          <td>
            {
              calculateMeanOfTableValues("IRR_FRACTION")
            }
          </td>
          <td>
            {
              calculateMeanOfTableValues("DAILY_PAR")
            }
          </td>
          <td>
            {calculateMeanOfTableValues("STRESS_HYDRIQUE_ZT")}
          </td>
          <td>
            {calculateMeanOfTableValues("STRESS_HYDRIQUE_AGRIPV")}
          </td>
          <td>{calculateMeanOfTableValues("WATER_CONSUMPTION")}</td>
          <td>{calculateMeanOfTableValues("RAIN")}</td>
        </tr>
      </tbody>
    </table>
  </div>
};

export default AnalysisContent;
