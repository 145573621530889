import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import structuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { IAddPointForm } from "components/AddPointModal/AddPointModal";
import moment from "moment/moment";
import EditName from "components/EditName/EditName";
import { useDispatch } from "react-redux";
import { createStructure } from "store/structures/actions";
import MainContent from "components/MainContent/MainContent";
import { useFormik, FormikHelpers } from "formik";
import StructuresBody from "components/StructuresBody/StructuresBody";
import StructuresSchema from "pages/StructuresCreate/StructuresSchema";
import preRender from "assets/pre-render-partial.png";
import { drawStructureImage, getStructureTemplate } from "store/structures/api";
import { errorNotifications } from "utils/errorNotifications";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import StructuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import { successNotifications } from "utils/successNotifications";
import StructureTemplateModal from "components/StructureTemplateModal/StructureTemplateModal";

export interface IStructure {
  name: string;
  panel_height: number;
  panel_opacity: number;
  panel_x: number;
  panel_y: number;
  initial_offset_x: number;
  initial_offset_y: number;
  panels_number_x: number;
  panels_number_y: number;
  no_crop_zone: number;
  panels_gap_x: number;
  panels_gap_y: number;
  field_size_x: number;
  field_size_y: number;
  resolution: number;
  azimuth: number;
  structure_type: "tracker" | "fixed";
  static_angle: number;
  tracking_max_angle: number;
  backtracking: boolean;
  displacement_tracking: boolean;
  translation_max_delta: number;
  translation_init_delta: number;
  solar_position: "calculated" | "simple";
  azimuth_solar: number;
  zenith_solar: number;
  day: string | null;
  hour: number;
  points: IAddPointForm[];
  module_efficiency_STC: number;
  temperature_cefficients_power: number;
  albedo: number;
  bifacial_factor: number;
}

export interface IStructureFormik {
  name: string;
  panel_lower: number;
  panel_upper: number;
  panel_height: number;
  panel_opacity: number;
  no_crop_zone: number;
  panel_width: number;
  panel_length: number;
  initial_offset_x: number;
  initial_offset_y: number;
  panels_number_x: number;
  panels_number_y: number;
  panels_gap_x: number;
  panels_gap_y: number;
  field_size_x: number;
  field_size_y: number;
  resolution: number;
  azimuth: number;
  structure_type: "tracker" | "fixed";
  static_angle: number;
  tracking_max_angle: number;
  backtracking: boolean;
  displacement_tracking: boolean;
  translation_max_delta: number;
  translation_init_delta: number;
  solar_position: "calculated" | "simple";
  azimuth_solar: number;
  zenith_solar: number;
  day: string | null;
  hour: number;
  points: IAddPointForm[];
  module_efficiency_STC: number;
  temperature_cefficients_power: number;
  albedo: number;
  bifacial_factor: number;
}

export const getInitialValue = (
  structure_type: "fixed" | "tracker",
  intl: any
): IStructureFormik => {
  if (structure_type == "fixed")
    return {
      name: intl.formatMessage(StructuresCreateMessages.structureUnnamed),
      panel_lower: 1.41,
      panel_upper: 2.58,
      panel_height: 2,
      panel_opacity: 0.9,
      panel_width: 4.5,
      panel_length: 10,
      initial_offset_x: 5,
      initial_offset_y: 4,
      panels_number_x: 5,
      panels_number_y: 5,
      panels_gap_x: 0,
      panels_gap_y: 9,
      field_size_x: 50,
      field_size_y: 50,
      resolution: 10,
      no_crop_zone: 0,
      azimuth: 0,
      structure_type: "fixed",
      static_angle: 15,
      tracking_max_angle: 45,
      backtracking: true,
      displacement_tracking: false,
      translation_max_delta: 3,
      translation_init_delta: 0,
      solar_position: "calculated",
      azimuth_solar: 20,
      zenith_solar: 20,
      day: moment().format("yyyy-MM-DD"),
      hour: 12,
      points: [],
      module_efficiency_STC: 21.5,
      temperature_cefficients_power: -0.3,
      albedo: 0.2,
      bifacial_factor: 80,
    };
  else
    return {
      name: intl.formatMessage(StructuresCreateMessages.structureUnnamed),
      panel_lower: 3.52,
      panel_upper: 5.47,
      panel_height: 4.5,
      panel_opacity: 0.9,
      no_crop_zone: 0,
      panel_length: 8,
      panel_width: 2.25,
      initial_offset_x: 5,
      initial_offset_y: 8,
      panels_number_x: 6,
      panels_number_y: 5,
      panels_gap_x: 8,
      panels_gap_y: 0,
      field_size_x: 50,
      field_size_y: 50,
      resolution: 10,
      azimuth: 0,
      structure_type: "tracker",
      static_angle: 15,
      tracking_max_angle: 60,
      backtracking: true,
      displacement_tracking: false,
      translation_max_delta: 3,
      translation_init_delta: 0,
      solar_position: "calculated",
      azimuth_solar: 20,
      zenith_solar: 20,
      day: moment().format("yyyy-MM-DD"),
      hour: 12,
      points: [],
      module_efficiency_STC: 21.5,
      temperature_cefficients_power: -0.3,
      albedo: 0.2,
      bifacial_factor: 80,
    };
};

const StructuresCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id } = useParams();
  const [image, setImage] = useState(preRender);
  const [imageLoading, setImageLoading] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false)
  const intl = useIntl();
  const [checkIfTemplate, setCheckIfTemplate] = useState(false)

  const [structureTemplate, setStructureTemplate] = useState<IStructure[]>(Array());
  useEffect(() => {
    getStructureTemplate().then(({ data }) => {
      setStructureTemplate(data);
    });
  }, []);

  useEffect(() => {
    if (window.location.href.split("/").pop() == "create_from_template") {
      setTemplateModalOpen(true);
      setCheckIfTemplate(true)
    }
  }, []);

  const onBack = () =>
    navigate(
      ROUTES.STRUCTURES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const onEditPage = (id: number | string) =>
    navigate(
      ROUTES.STRUCTURES_EDIT.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      ).replace(
        ":id",
        id as string
      )
    );

  const onCreate = (
    values: IStructureFormik,
    actions: FormikHelpers<IStructureFormik>
  ) => {
    const obj = {
      ...values,
      panel_y:
        values.structure_type == "fixed"
          ? values.panel_width
          : values.panel_length,
      panel_x:
        values.structure_type == "fixed"
          ? values.panel_length
          : values.panel_width,
      panels_gap_x:
        values.panels_gap_x +
        (values.structure_type == "fixed" ? values.panel_length : 0),
      panels_gap_y:
        values.panels_gap_y +
        (values.structure_type == "tracker" ? values.panel_length : 0),
    };

    dispatch(
      createStructure({
        ...obj,
        clientId: client_id,
        projectId: project_id,
        callback: ({ data }: any) => onEditPage(data.id),
        finallyCallback: () => {
          actions.setSubmitting(false)
          successNotifications({ title: intl.formatMessage(commonMessages.create), message: intl.formatMessage(commonMessages.successCreate, { objet_type: intl.formatMessage(commonMessages.structure) }) })
        },
      })
    );
  };

  const formik = useFormik({
    validationSchema: StructuresSchema(intl),
    onSubmit: onCreate,
    initialValues: getInitialValue("fixed", intl),
  });

  useEffect(() => {
    if (!checkIfTemplate)
      formik.setValues({ ...getInitialValue(formik.values.structure_type, intl), name: formik.values.name });
    else
      setCheckIfTemplate(false)

  }, [formik.values.structure_type]);

  usePrompt(
    !isEqual(
      formik.values,
      getInitialValue(formik.values.structure_type, intl)
    ) && !formik.isSubmitting
  );

  const onDraw = () => {
    const obj = {
      ...formik.values,
      panel_y:
        formik.values.structure_type == "fixed"
          ? formik.values.panel_width
          : formik.values.panel_length,
      panel_x:
        formik.values.structure_type == "fixed"
          ? formik.values.panel_length
          : formik.values.panel_width,
      panels_gap_x:
        formik.values.panels_gap_x +
        (formik.values.structure_type == "fixed" ? formik.values.panel_length : 0),
      panels_gap_y:
        formik.values.panels_gap_y +
        (formik.values.structure_type == "tracker" ? formik.values.panel_length : 0),
    }
    setImageLoading(true);
    drawStructureImage({
      clientId: client_id,
      projectId: project_id,
      ...obj,
    })
      .then((img) => {
        setImage(img);
        setImageLoading(false);
      })
      .catch(async (error) => {
        const text = await error.response.data.text();
        const errorObj = JSON.parse(text);
        errorNotifications(errorObj.data);
        setImage(preRender);
        setImageLoading(false);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                structuresCreateMessages.structureName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button variant="text" type="button" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <StructuresBody
          formik={formik}
          image={image}
          onImageLoading={imageLoading}
          onDraw={onDraw}
          setInitialValue={(structure_type: "fixed" | "tracker") => formik.setValues({ ...getInitialValue(structure_type, intl), name: formik.values.name })}
        />
        <StructureTemplateModal
          isOpen={templateModalOpen}
          templates={structureTemplate}
          onHideModal={() => setTemplateModalOpen(false)}
          onSelectStructureTemplate={(template: any) => {
            formik.setValues({ ...template, no_crop_zone: 0 })
            setTemplateModalOpen(false)
          }}
          onBack={onBack}
        />
      </MainContent>
    </form>
  );
};

export default StructuresCreate;
