import { useState } from 'react';
import styled from './styled.module.scss'
import clsx from 'clsx';
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { FormattedMessage, useIntl } from 'react-intl';
import CommonMessages from 'components/common/CommonMessages';
import { ICON_NAMES_ENUM, Icon } from 'components/common/icon/Icon';
import SimulationCreateMessages from 'pages/SimulationCreate/SimulationCreateMessages';
import SimulationDropItemMessages from 'components/SimulationDropItem/SimulationDropItemMessages';
import DatasetRenderList from 'components/SimulationDropItem/DatasetRenderList';
import SteeringAlgorithmsRenderList from 'components/SimulationDropItem/SteeringAlgorithmsRenderList';
import CropsRenderList from 'components/SimulationDropItem/CropsRenderList';
import SoilInformationsRenderList from 'components/WaterBalanceDropItem/SoilInformationsRenderList';
import StructuresRenderList from 'components/SimulationDropItem/StructuresRenderList';
import Line from 'components/WaterBalanceDropItem/Line';
import WaterBalanceResultBodyMessages from 'components/WaterBalanceResultBody/WaterBalanceResultBodyMessages';

const SimulationConfigContent = ({ result }: any) => {

	const [isStructureOpen, setIsStructureOpen] = useState(false);
	const [isSoilInformationOpen, setIsSoilInformation] = useState(false);
	const [isCropOpen, setIsCropOpen] = useState(false);
	const [isSteeringAlgorithmOpen, setIsSteeringAlgorithmOpen] = useState(false);
	const [isWeatherDatasetOpen, setIsWeatherDatasetOpen] = useState(false);
	const [isProductionDatasetOpen, setIsProductionDatasetOpen] = useState(false);
	const [isETPDatasetOpen, setIsETPDatasetOpen] = useState(false);

	const intl = useIntl()

	return <div className={styled.wrapper}>
		<div className={styled.essenceContainer}>
			<Line
				title={intl.formatMessage(
					WaterBalanceResultBodyMessages.simulationFrequency
				)}
				value={result.simulation.frequency}
			/>
		</div>
		<div className={styled.essenceContainer}>
			<Line
				title={intl.formatMessage(
					WaterBalanceResultBodyMessages.resolution
				)}
				value={result.simulation.resolution}
			/>
		</div>

		{/*STRUCTURE*/}
		<div
			className={styled.essenceContainer}
			onClick={() => {
				setIsStructureOpen(!isStructureOpen);
			}}
		>
			<div className={styled.essenceRow}>
				<div className={styled.essenceLabel}>
					<button
						className={clsx(styled.toggle, {
							[styled.toggleOpen]: isStructureOpen,
						})}
						type="button"
					>
						<Arrow />
					</button>
					<Structures />
					<span>
						<FormattedMessage {...CommonMessages.structures} />
					</span>
				</div>
				<span className={styled.essenceName}>
					{result.simulation.structure.name}
				</span>
			</div>
			<div
				className={clsx(styled.essenceContent, {
					[styled.essenceContentShow]: isStructureOpen,
				})}
			>
				<StructuresRenderList selected={result.simulation.structure} />
			</div>
		</div>

		{/*SOIL INFORMATION*/}
		<div
			className={styled.essenceContainer}
			onClick={() => {
				setIsSoilInformation(!isSoilInformationOpen);
			}}
		>
			<div className={styled.essenceRow}>
				<div className={styled.essenceLabel}>
					<button
						className={clsx(styled.toggle, {
							[styled.toggleOpen]: isSoilInformationOpen,
						})}
						type="button"
					>
						<Arrow />
					</button>
					<Icon
						name={ICON_NAMES_ENUM.terrain_icon}
						className={styled.file__icon}
					/>
					<span>
						<FormattedMessage {...CommonMessages.soil_information} />
					</span>
				</div>
				<span className={styled.essenceName}>
					{result.simulation.water_balance.name}
				</span>
			</div>
			<div
				className={clsx(styled.essenceContent, {
					[styled.essenceContentShow]: isSoilInformationOpen,
				})}
			>
				<SoilInformationsRenderList
					selected={result.simulation.water_balance}
				/>
			</div>
		</div>

		{/* CROPS */}

		{result.simulation.crop_for_water_balance ? (
			<div
				className={styled.essenceContainer}
				onClick={() => {
					setIsCropOpen(!isCropOpen);
				}}
			>
				<div className={styled.essenceRow}>
					<div className={styled.essenceLabel}>
						<button
							className={clsx(styled.toggle, {
								[styled.toggleOpen]: isCropOpen,
							})}
							type="button"
						>
							<Arrow />
						</button>
						<Crops />
						<span>
							<FormattedMessage {...CommonMessages.crops} />
						</span>
					</div>
					<span className={styled.essenceName}>
						{result.simulation.crop_for_water_balance.name}
					</span>
				</div>
				<div
					className={clsx(styled.essenceContent, {
						[styled.essenceContentShow]: isCropOpen,
					})}
				>
					<CropsRenderList
						selected={result.simulation.crop_for_water_balance}
					/>
				</div>
			</div>
		) : (
			<></>
		)}

		{/*STEERING ALGORITHM*/}
		{result.simulation.steering_algorithm_for_water_balance && (
			<div
				className={styled.essenceContainer}
				onClick={() => {
					setIsSteeringAlgorithmOpen(!isSteeringAlgorithmOpen);
				}}
			>
				<div className={styled.essenceRow}>
					<div className={styled.essenceLabel}>
						<button
							className={clsx(styled.toggle, {
								[styled.toggleOpen]: isSteeringAlgorithmOpen,
							})}
							type="button"
						>
							<Arrow />
						</button>
						<DataTemp />
						<span>
							<FormattedMessage
								{...SimulationDropItemMessages.steeringAlgorithms}
							/>
						</span>
					</div>
					<span className={styled.essenceName}>
						{result.simulation.steering_algorithm_for_water_balance.name}
					</span>
				</div>
				<div
					className={clsx(styled.essenceContent, {
						[styled.essenceContentShow]: isSteeringAlgorithmOpen,
					})}
				>
					<SteeringAlgorithmsRenderList
						selected={
							result.simulation.steering_algorithm_for_water_balance
						}
					/>
				</div>
			</div>
		)}

		{/*WEATHER DATASET*/}
		<div
			className={styled.essenceContainer}
			onClick={() => {
				setIsWeatherDatasetOpen(!isWeatherDatasetOpen);
			}}
		>
			<div className={styled.essenceRow}>
				<div className={styled.essenceLabel}>
					<button
						className={clsx(styled.toggle, {
							[styled.toggleOpen]: isWeatherDatasetOpen,
						})}
						type="button"
					>
						<Arrow />
					</button>
					<DataTemp />
					<span>
						<FormattedMessage
							{...SimulationDropItemMessages.weatherDatasets}
						/>
					</span>
				</div>
				<span className={styled.essenceName}>
					{result.simulation.weather_dataset.name}
				</span>
			</div>
			<div
				className={clsx(styled.essenceContent, {
					[styled.essenceContentShow]: isWeatherDatasetOpen,
				})}
			>
				<DatasetRenderList selected={result.simulation.weather_dataset} />
			</div>
		</div>

		{/*PRODUCTION DATASET*/}
		{result.simulation.production_dataset &&
			result.simulation.production_dataset.name != "__None__" && (
				<div
					className={styled.essenceContainer}
					onClick={() => {
						setIsProductionDatasetOpen(!isProductionDatasetOpen);
					}}
				>
					<div className={styled.essenceRow}>
						<div className={styled.essenceLabel}>
							<button
								className={clsx(styled.toggle, {
									[styled.toggleOpen]: isProductionDatasetOpen,
								})}
								type="button"
							>
								<Arrow />
							</button>
							<DataElec />
							<span>
								<FormattedMessage
									{...SimulationDropItemMessages.productionDatasets}
								/>
							</span>
						</div>
						<span className={styled.essenceName}>
							{result.simulation.production_dataset.name}
						</span>
					</div>
					<div
						className={clsx(styled.essenceContent, {
							[styled.essenceContentShow]: isProductionDatasetOpen,
						})}
					>
						<DatasetRenderList
							selected={result.simulation.production_dataset}
						/>
					</div>
				</div>
			)}

		{result.simulation.ETP_dataset && (
			<div
				className={styled.essenceContainer}
				onClick={() => {
					setIsETPDatasetOpen(!isETPDatasetOpen);
				}}
			>
				<div className={styled.essenceRow}>
					<div className={styled.essenceLabel}>
						<button
							className={clsx(styled.toggle, {
								[styled.toggleOpen]: isETPDatasetOpen,
							})}
							type="button"
						>
							<Arrow />
						</button>
						<DataTemp />
						<span>
							<FormattedMessage
								{...SimulationDropItemMessages.etpDatasets}
							/>
						</span>
					</div>
					<span className={styled.essenceName}>
						{result.simulation.ETP_dataset.name}
					</span>
				</div>
				<div
					className={clsx(styled.essenceContent, {
						[styled.essenceContentShow]: isETPDatasetOpen,
					})}
				>
					<DatasetRenderList selected={result.simulation.ETP_dataset} />
				</div>
			</div>
		)}

		<div className={styled.essenceContainer}>
			<div className={styled.essenceRow}>
				<div className={styled.essenceLabel}>
					<Icon
						name={ICON_NAMES_ENUM.calculator}
						className={clsx(styled.icon, styled.toggle)}
					/>
					<span>
						<FormattedMessage
							{...SimulationCreateMessages.grasslandPrimaryYieldCalculation}
						/>
					</span>
				</div>
				<input
					type="checkbox"
					checked={result.simulation.grassland_primary_yield_calculation}
					disabled
					onChange={(val) => console.log(val)}
				/>
			</div>
		</div>
	</div>


}

export default SimulationConfigContent;