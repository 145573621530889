import MainContent from "components/MainContent/MainContent";
import styled from "./styled.module.scss";
import commonMessages from "components/common/CommonMessages";
import { ROUTES } from "constants/routes";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  copyBatchWaterBalance,
  deleteBatchWaterBalance,
  getBatchesWaterBalance,
} from "store/batches_water_balance/actions";
import { getBatchesWaterBalanceListState } from "store/batches_water_balance/selectors";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import BatchesCard from "components/BatchesCard/BatchesCard";
import Button from "components/common/button/Button";
import { getBatchesState } from "store/batches/selectors";

const BatchesWaterBalance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const batches_water_balance = useSelector(getBatchesWaterBalanceListState);

  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(
      getBatchesWaterBalance({ clientId: client_id, projectId: project_id })
    );
  }, [project_id, client_id]);

  const onDelete = () => {
    dispatch(
      deleteBatchWaterBalance({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: deleteId,
        callback: () => setDeleteId(null),
      })
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyBatchWaterBalance({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: id,
      })
    );
  };

  const onShowWarning = (id: number) => {
    setDeleteId(id);
  };

  const onCreatePress = () => {
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_CREATE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.batches_water_balance} />
          </h2>
          <Button iconBefore={<Plus />} onClick={onCreatePress}>
            <FormattedMessage {...commonMessages.createNew} />
          </Button>
        </>
      }
    >
      <div className={styled.list}>
        {batches_water_balance.map((batch: any) => (
          <BatchesCard
            key={batch.id}
            name={batch.name}
            date={batch.updated_at}
            rows_number={batch.rows_number}
            onEdit={() =>
              navigate(
                ROUTES.BATCHES_WATER_BALANCE_EDIT.replace(":client_id", client_id as string)
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(batch.id))
              )
            }
            onDelete={() => onShowWarning(batch.id)}
            onCopy={onCopy(batch.id)}
          />
        ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={deleteId !== null}
          onHide={() => setDeleteId(null)}
        />
      </div>
    </MainContent>
  );
};

export default BatchesWaterBalance;
