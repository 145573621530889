import { ParamsInterests } from "components/BatchesWaterBalanceBody/BatchesWaterBalanceBody";
import MainContent from "components/MainContent/MainContent";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import {
  IInterestParamsWaterBalanceItem,
  useWaterBalanceParamsList,
  IInterestNameVariantsWaterBalance,
} from "components/WaterBalanceParametersInterest/useParamsList";
import { Loader } from "components/common/loader/Loader";
import { ROUTES } from "constants/routes";
import { useOptionData } from "hooks/useOptionData";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  cancelBatchWaterBalanceSimulation,
  deleteBatchWaterBalanceResult,
  getBatchWaterBalanceResults,
  resetBatchWaterBalanceResults,
} from "store/batches_water_balance/actions";
import {
  getBatchWaterBalance,
  getBatchWaterBalanceMetadata,
  getBatchWaterBalanceResultFiles,
  getBatchWaterBalanceSimulationResultFiles,
} from "store/batches_water_balance/api";
import { getBatchWaterBalanceResultsState } from "store/batches_water_balance/selectors";
import { getNavigationState } from "store/navigation/selectors";
import { downloadFile } from "utils/downloadFile";
import styled from "./styled.module.scss";
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "components/common/CommonMessages";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import BatchesWaterBalanceResultMessages from "./BatchesWaterBalanceResultMessages";
import ParametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import { Tooltip } from "@mui/material";
import { getWaterBalance } from "store/water_balances/actions";

const REFRESH_DATA_TIME = 10000;

type simulationsProgressType = {
  current: number;
  total: number;
}[];

const BatchesWaterBalanceResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { client_id, project_id, batch_water_balance_id, id } = useParams();
  const params = useWaterBalanceParamsList();
  const batchResult = useSelector(getBatchWaterBalanceResultsState);
  const {
    weather_datasets,
    production_datasets,
    etp_datasets,
    batches_water_balance,
  } = useSelector(getNavigationState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isMetadataFileLoading, setIsMetadataFileLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isSimulationFileLoading, setIsSimulationFileLoading] = useState<
    Record<number, boolean>
  >({});
  const [isIrrigation, setIsIrrigation] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    getBatchWaterBalance({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
    }).then(({ data }) => {
      setIsIrrigation(data.simulation.water_balance.irrigation)
    })
  }, [client_id, project_id, batch_water_balance_id]);

  const findFullName = (findId: number) => {
    for (const batch of batches_water_balance) {
      for (const result of batch.results) {
        const index = result.simulations.findIndex(
          ({ id }: any) => id === findId
        );

        if (index !== -1) {
          return `${batch.name} - ${result.name} - ${result.simulations[index].name}`;
        }
      }
    }
    return batchResult.name;
  };

  const isSuccessSimulation = batchResult?.status === StatusesEnum.COMPLETED;
  const isCanceledSimulation = batchResult?.status === StatusesEnum.CANCELED;
  const isProgressSimulation = batchResult?.status === StatusesEnum.RUNNING;

  const weatherDatasetOptions = useOptionData(weather_datasets);
  const productionDatasetOptions = useOptionData(production_datasets);
  const etpDatasetOptions = useOptionData(etp_datasets);

  const onDownloadSimulation = (simulation_water_balance_result: any) => () => {
    setIsSimulationFileLoading((prev) => ({
      ...prev,
      [simulation_water_balance_result.id]: true,
    }));
    getBatchWaterBalanceSimulationResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
      simulationWaterBalanceId: simulation_water_balance_result.id,
    })
      .then((href) => {
        downloadFile(
          href,
          `${findFullName(simulation_water_balance_result.id)}.zip`
        );
      })
      .finally(() => {
        setIsSimulationFileLoading((prev) => ({
          ...prev,
          [simulation_water_balance_result.id]: false,
        }));
      });
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getBatchWaterBalanceResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  const onDownloadMetadata = () => {
    setIsMetadataFileLoading(true);
    getBatchWaterBalanceMetadata({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.csv`);
      })
      .finally(() => setIsMetadataFileLoading(false));
  };

  const onCancel = () => {
    setIsCanceling(true);
    dispatch(
      cancelBatchWaterBalanceSimulation({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
        callback: () => setIsCanceling(false),
      })
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_EDIT.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", batch_water_balance_id as string)
    );
  };

  const onDelete = () => {
    dispatch(
      deleteBatchWaterBalanceResult({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
        callback: onBack,
      })
    );
  };

  const getResult = () => {
    dispatch(
      getBatchWaterBalanceResults({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: id,
      })
    );
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressSimulation) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [batchResult]);

  useEffect(() => {
    getResult();

    return () => {
      dispatch(resetBatchWaterBalanceResults());
    };
  }, [client_id, project_id, batch_water_balance_id, id]);

  const getCurrentField = useCallback(
    (value: IInterestNameVariantsWaterBalance, rows: ParamsInterests) => {
      const param = params.find(
        ({ name }: IInterestParamsWaterBalanceItem) => name === value
      );

      if (!param) return;

      if (param.type === "ETP_dataset") {
        const selectedId = rows[value];
        const selectedValue = etpDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "weather_dataset") {
        const selectedId = rows[value];
        const selectedValue = weatherDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "production_dataset") {
        const selectedId = rows[value];
        const selectedValue = productionDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }
    },
    [params]
  );

  const risk_analysis_table = useMemo(() => {
    if (!isSuccessSimulation)
      return null
    const ret: any[][] = []

    for (let index = 0; index < batchResult.batch_water_balance_risk_analysis.length; index++) {
      const element = batchResult.batch_water_balance_risk_analysis[index];
      for (let i = 0; i <= ret.length; i++) {
        if (i == ret.length) {
          ret.push([element])
          break
        }
        else
          if (!ret[i].find((item: any) => item.period_name == element.period_name)) {
            ret[i].push(element)
            break
          }


      }

    }
    return ret
  }, [batchResult])


  if (!batchResult) return <Loader />;

  const simulationsProgressData: simulationsProgressType = batchResult.rows.map(
    ({ simulation_water_balance_result }: any) => {
      const isRunningMain =
        simulation_water_balance_result?.status === StatusesEnum.RUNNING_MAIN;
      const isPrepare =
        simulation_water_balance_result?.status === StatusesEnum.PREPARE;
      const isCompleted =
        simulation_water_balance_result?.status === StatusesEnum.COMPLETED;

      const sumPeriodsPath =
        simulation_water_balance_result?.result_data?.reduce(
          (a: any, { current }: any) => {
            a += current;
            return a;
          },
          0
        );

      const mainPath =
        isRunningMain || isPrepare
          ? simulation_water_balance_result.current
          : simulation_water_balance_result.length;

      const current = isCompleted
        ? simulation_water_balance_result.total_length || 100
        : mainPath + sumPeriodsPath || 0;

      return {
        current,
        total: simulation_water_balance_result.total_length || 100,
      };
    }
  );

  const simulationsProgress = simulationsProgressData.map(
    ({ current, total }) => (current / (total / 100)).toFixed(0)
  );

  const totalProgressData = simulationsProgressData.reduce(
    (a, c) => {
      a.current += c.current;
      a.total += c.total;
      return a;
    },
    { current: 0, total: 0 }
  );

  const totalProgress = (
    totalProgressData.current /
    (totalProgressData.total / 100)
  ).toFixed(0);

  const chartToCsv = (chartData: any) => () => {
    const generateCSV = (data: any) => {
      let csvContent = "";

      if (data.length === 0) {
        return csvContent;
      }
      const keys = Object.keys(data[0]);
      csvContent += keys.join(",") + "\n";
      data.forEach((item: any) => {
        const row = keys.map((key) => item[key]);
        csvContent += row.join(",") + "\n";
      });
      return csvContent;
    };

    const saveFile = (file: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    };

    const csvData = generateCSV(chartData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "data.csv", {
          type: "text/csv;charset=utf-8",
        });
        URL.revokeObjectURL(url);
        saveFile(file);
      });
  };



  return (
    <MainContent
      header={
        <>
          <div className={styled.row}>
            <h2 className={styled.title}>{batchResult.name}</h2>
            <StatusLabel status={batchResult.status} />
            {!isSuccessSimulation && (
              <span className={styled.progressText}>{totalProgress}%</span>
            )}
          </div>
          <div className={styled.headerRow}>
            {(isSuccessSimulation || isCanceledSimulation) && (
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onDelete}
                className={styled.deleteButton}
              >
                <FormattedMessage {...CommonMessages.delete} />
              </Button>
            )}

            {isProgressSimulation && (
              <Button
                variant="outlined"
                disabled={isCanceling}
                isLoading={isCanceling}
                onClick={onCancel}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            )}

            {isSuccessSimulation && (
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={isFileLoading}
                  isLoading={isFileLoading}
                  onClick={onDownload}
                >
                  <FormattedMessage {...CommonMessages.downloadAll} />
                </Button>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={true}
                  isLoading={isMetadataFileLoading}
                  onClick={onDownloadMetadata}
                  className={styled.disable}
                >
                  <FormattedMessage {...CommonMessages.downloadAllMetadata} />
                </Button>
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={styled.tableContainer}>
        <table className={styled.table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  {...BatchesWaterBalanceResultMessages.waterBalancePage}
                />
              </th>

              {Object.keys(batchResult.rows[0].batch_water_balance_row).map(
                (item) => (
                  <th key={item}>
                    <FormattedMessage
                      {...ParametersInterestMessages[
                      item as IInterestNameVariantsWaterBalance
                      ]}
                    />
                  </th>
                )
              )}

              <th>
                <FormattedMessage
                  {...BatchesWaterBalanceResultMessages.status}
                />
              </th>

              <th className={styled.lastColumn} />
            </tr>
          </thead>

          <tbody>
            {batchResult.rows.map((simulation: any, index: number) => (
              <tr
                key={simulation.simulation_water_balance_result.id}
                className={clsx({
                  [styled.successRow]:
                    simulation.simulation_water_balance_result.status ===
                    StatusesEnum.COMPLETED,
                  [styled.failedRow]:
                    simulation.simulation_water_balance_result.status ===
                    StatusesEnum.FAILED,
                })}
              >
                <td>
                  <Link
                    className={styled.link}
                    to={ROUTES.BATCHES_WATER_BALANCE_SIMULATION_RESULT.replace(
                      ":client_id",
                      client_id as string
                    )
                      .replace(":project_id", project_id as string)
                      .replace(
                        ":batch_water_balance_id",
                        batch_water_balance_id as string
                      )
                      .replace(":result_id", id as string)
                      .replace(
                        ":simulation_water_balance_id",
                        simulation.simulation_water_balance_result.id as string
                      )}
                  >
                    <span>
                      {simulation.simulation_water_balance_result.name}
                    </span>
                    <LinkIcon />
                  </Link>
                </td>
                {Object.keys(simulation.batch_water_balance_row).map((item) => (
                  <td
                    key={`${item}-${simulation.simulation_water_balance_result.id}`}
                  >
                    {getCurrentField(
                      item as IInterestNameVariantsWaterBalance,
                      simulation.batch_water_balance_row
                    )}
                  </td>
                ))}
                <td>
                  <StatusLabel
                    status={
                      simulation.simulation_water_balance_result
                        .status as StatusesEnum
                    }
                  />
                </td>
                <td>
                  {simulation.simulation_water_balance_result.status ===
                    StatusesEnum.COMPLETED ? (
                    <Button
                      variant="text"
                      disabled={
                        isSimulationFileLoading[
                        simulation.simulation_water_balance_result.id
                        ]
                      }
                      isLoading={
                        isSimulationFileLoading[
                        simulation.simulation_water_balance_result.id
                        ]
                      }
                      className={clsx(styled.button)}
                      onClick={onDownloadSimulation(
                        simulation.simulation_water_balance_result
                      )}
                    >
                      <Arrow />
                    </Button>
                  ) : (
                    <span className={styled.progressText}>
                      {simulationsProgress[index]}%
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {isSuccessSimulation && risk_analysis_table && isIrrigation != undefined ? risk_analysis_table.map((item: any, index: number) =>
        (
          <div className={styled.waterBalanceChartsContainer}>
            <div style={{ maxWidth: "95%" }}>
              <table className={styled.waterBalanceTable}>
                <thead>
                  <tr>
                    <th colSpan={2} id="none" />
                    <th
                      colSpan={
                        item.length
                      }
                    >
                      {batchResult.rows[index].simulation_water_balance_result.name}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      {intl.formatMessage(
                        WaterBalanceResultBodyMessages.indicator
                      )}
                    </th>
                    {item.map(
                      (item: any) => (
                        <th
                          key={item.period_name}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.period_name ==
                            "___1periodAsYear-forWaterBalance___"
                            ? "Year"
                            : item.period_name}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={4}>
                      {intl.formatMessage(
                        isIrrigation ? WaterBalanceResultBodyMessages.irrigationValue : WaterBalanceResultBodyMessages.waterStressNumber
                      )}
                    </th>
                    <th>Control Zone</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "stressControl"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.control_zone}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Under Panels</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "stressUnder"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.under_panels}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Between Panels</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "stressBetween"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.between_panels}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Agri PV</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "stressAgri"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.agriPV}
                        </td>
                      )
                    )}
                  </tr>
                </tbody>
                {/* <tbody>
                  <tr>
                    <th rowSpan={4}>
                      {intl.formatMessage(
                        WaterBalanceResultBodyMessages.irrigationValue
                      )}
                    </th>
                    <th>Control Zone</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "irrigationControl"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.irrigation ? item.control_zone : 0}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Under Panels</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "irrigationUnder"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.irrigation ? item.under_panels : 0}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Between Panels</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "irrigationBetween"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.irrigation ? item.between_panels : 0}
                        </td>
                      )
                    )}
                  </tr>
                  <tr>
                    <th>Agri PV</th>
                    {item.map(
                      (item: any) => (
                        <td
                          key={item.period_name + "irrigationAgri"}
                          className={clsx({
                            [styled.yearTableContent]:
                              item.period_name ==
                              "___1periodAsYear-forWaterBalance___",
                          })}
                        >
                          {item.irrigation ? item.agriPV : 0}
                        </td>
                      )
                    )}
                  </tr>
                </tbody> */}
              </table>
            </div>
            <Tooltip
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  item
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </Tooltip>
          </div>)
        ) : (
          <></>
        )
        }
      </div>
    </MainContent>
  );
};

export default BatchesWaterBalanceResult;
