import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
}

const ETPContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();
  const unitePeriodIrrOptions = useMemo(() => {
    return irrUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [irrUnites]);
  const [selectedPeriodETPUnite, setSelectedPeriodETPUnite] =
    useState<"Fraction">("Fraction");
  const [inactiveKeysPeriodETP, setInactiveKeyPeriodETP] = useState(
    Array<string>()
  );

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const period_ETP_points = useMemo(() => {
    if (result && selectedPeriodETPUnite) {
      return result.simulation.crop_for_water_balance.periods.map(
        (period: any, index: number) => {
          const values = getValueInCropPeriod(period);

          const period_etp_zt = values.reduce(
            (acc: number, item: any) => acc + item.etp_zt,
            0
          );
          const period_etp_under_panel = values.reduce(
            (acc: number, item: any) => acc + item.etp_under_panel,
            0
          );
          const period_etp_between_panel = values.reduce(
            (acc: number, item: any) => acc + item.etp_between_panel,
            0
          );
          const period_etp_agri_pv = values.reduce(
            (acc: number, item: any) => acc + item.etp_agri_pv,
            0
          );

          return {
            name: period.name,
            etp_witness_zone:
              Math.round(((period_etp_zt * 100) / period_etp_zt) * 100) / 100,
            etp_under_panel:
              Math.round(
                ((period_etp_under_panel * 100) / period_etp_zt) * 100
              ) / 100,
            etp_between_panel:
              Math.round(
                ((period_etp_between_panel * 100) / period_etp_zt) * 100
              ) / 100,
            etp_agriPV:
              Math.round(((period_etp_agri_pv * 100) / period_etp_zt) * 100) /
              100,
          };
        }
      );
    }
    return null;
  }, [result, selectedPeriodETPUnite]);

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.uniteSelector}></div>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)}
            </div>
          </TooltipMUI>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  period_ETP_points,
                  `${result.simulation.name} ETP per Stage (${selectedPeriodETPUnite})`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>

        <div id={intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={period_ETP_points}
              width={500}
              height={500}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"name"}
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis yAxisId="left" interval={0} orientation="left">
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideLeft"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.ETPAgriPVZone
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodETP.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodETP([
                      ...inactiveKeysPeriodETP,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodETP;
                    temp.splice(
                      inactiveKeysPeriodETP.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodETP([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"etp_witness_zone"}
                name="Control Zone"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "etp_witness_zone"
                  )
                    ? true
                    : false
                }
                unit={"%"}
              />
              <Bar
                type="monotone"
                dataKey={"etp_under_panel"}
                name="Under Panels"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "etp_under_panel"
                  )
                    ? true
                    : false
                }
                unit={"%"}
              />
              <Bar
                type="monotone"
                dataKey={"etp_between_panel"}
                name="Between Panels"
                yAxisId="left"
                fill={"#6666AA"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "etp_between_panel"
                  )
                    ? true
                    : false
                }
                unit={"%"}
              />
              <Bar
                type="monotone"
                dataKey={"etp_agriPV"}
                name="AgriPV Zone"
                yAxisId="left"
                fill={"#444444"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "etp_agriPV"
                  )
                    ? true
                    : false
                }
                unit={"%"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            SimulationResultBodyMessages.hintChartToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng(
              "Table " +
                intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
      className={styled.dataTable}
      id={
        "Table " +
        intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)
      }
    >
      <thead>
        <tr>
          <th>
            <FormattedMessage {...CropCreateMessages.stageName} />
          </th>
          <th>
            <FormattedMessage
              {...SimulationResultBodyMessages.EtpRefZone}
              values={{
                unit: "%",
              }}
            />
          </th>
          <th>
            <FormattedMessage
              {...SimulationResultBodyMessages.EtpUnderPanels}
              values={{
                unit: "%",
              }}
            />
          </th>
          <th>
            <FormattedMessage
              {...SimulationResultBodyMessages.EtpBetweenPanels}
              values={{
                unit: "%",
              }}
            />
          </th>
          <th>
            <FormattedMessage
              {...SimulationResultBodyMessages.EtpAgriPVZone}
              values={{
                unit: "%",
              }}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {period_ETP_points.map((item: any) => (
          <tr key={`tableIndex${item.name}`}>
            <td>{item.name}</td>
            <td>{item.etp_witness_zone}</td>
            <td>{item.etp_under_panel}</td>
            <td>{item.etp_between_panel}</td>
            <td>{item.etp_agriPV}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default ETPContent;
