import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToPng from "utils/chartToPng";
import chartToCsv from "utils/chartToCsv";
import CustomTick from "components/CustomTick/CustomTick";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
}

const ProductionContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();

  const [inactiveKeysPeriodProduction, setInactiveKeyPeriodProduction] = useState(
    Array<string>()
  );

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const period_production_points = useMemo(() => {
    if (result) {
      return result.simulation.crop_for_water_balance.periods.map(
        (period: any, index: number) => {
          const values = getValueInCropPeriod(period);

          const period_production = Math.round(values.reduce(
            (acc: number, item: any) => acc + item.production,
            0
          ) * 100) / 100;
          const period_production_max = Math.round(values.reduce(
            (acc: number, item: any) => acc + item.production_max,
            0
          ) * 100) / 100;

          return {
            name: period.name,
            production: period_production,
            production_max: period_production_max,
          };
        }
      );
    }
    return null;
  }, [result]);

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.uniteSelector}></div>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)}
            </div>
          </TooltipMUI>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  period_production_points,
                  `${result.simulation.name} Production per Stage ${result.simulation.crop.name}`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>

        <div id={intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={period_production_points}
              width={500}
              height={500}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"name"}
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis yAxisId="left" interval={0} orientation="left">
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideLeft"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.ETPAgriPVZone
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodProduction.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodProduction([
                      ...inactiveKeysPeriodProduction,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodProduction;
                    temp.splice(
                      inactiveKeysPeriodProduction.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodProduction([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"production_max"}
                name="Production sun tracking"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodProduction.find(
                    (inactiveKey) => inactiveKey == "production"
                  )
                    ? true
                    : false
                }
                unit={"kWh/kWc"}
              />
              <Bar
                type="monotone"
                dataKey={"production"}
                name="Production agriPV"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodProduction.find(
                    (inactiveKey) => inactiveKey == "production_max"
                  )
                    ? true
                    : false
                }
                unit={"kWh/kWc"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            SimulationResultBodyMessages.hintChartToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng(
              "Table " +
              intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
        className={styled.dataTable}
        id={
          "Table " +
          intl.formatMessage(SimulationResultBodyMessages.ProdPerPeriod)
        }
      >
        <thead>
          <tr>
            <th>
              <FormattedMessage {...CropCreateMessages.stageName} />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.productionSunStracking}
                values={{
                  unit: "kWh/kWc",
                }}
              />
            </th>
            <th>
              <FormattedMessage
                {...SimulationResultBodyMessages.prodAgriPV}
                values={{
                  unit: "kWh/kWc",
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {period_production_points.map((item: any) => (
            <tr key={`tableIndex${item.name}`}>
              <td>{item.name}</td>
              <td>{item.production_max}</td>
              <td>{item.production}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductionContent;
