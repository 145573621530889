import AvailableWaterStockContent from './AvailableWaterStockContent';
import ETPContent from './ETPContent';
import WaterConsumptionContent from './WaterConsumptionContent';
import WaterDeficitContent from './WaterDeficitContent';
import styled from './styled.module.scss'

const WaterbalanceContent = ({ result }: any) => {
	return <div className={styled.list}>
		<div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<AvailableWaterStockContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterConsumptionContent result={result} />
			</div>
		</div>
		<div className={styled.limiter} />
		<div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterDeficitContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<ETPContent result={result} />
			</div>
		</div>
	</div>


}

export default WaterbalanceContent;