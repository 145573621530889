import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import moment from "moment";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";

const RUUnites = ["Fill (mm)", "Fill (%)"];

class IrradianceContentProps {
  result: any;
}

const AvailableWaterStockContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();

  const [inactiveKeysDailyRU, setInactiveKeyDailyRU] = useState(
    Array<string>()
  );

  const uniteDailyRUOptions = useMemo(() => {
    return RUUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [RUUnites]);

  const [selectedDailyRUUnite, setSelectedDailyRUUnite] = useState<
    "Fill (mm)" | "Fill (%)"
  >("Fill (mm)");

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const period = result.simulation.crop_for_water_balance.periods.find(
        (period: any) =>
          new Date(period.start_date) <= new Date(label) &&
          new Date(label) <= new Date(period.end_date)
      );

      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{moment(label).format("DD MMM")}</p>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {period ? period.name : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const daily_RU_points = useMemo(() => {
    if (result && selectedDailyRUUnite) {
      const result_data_selected = result.result_data[0];
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      if (result.simulation.water_balance.irrigation) {
        for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
          points.push({
            date: result_data_selected.result_daily_data[i].date,
            zt:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_ref
                  : (result_data_selected.result_daily_data[i].rfu_ref /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_under_panels
                  : (result_data_selected.result_daily_data[i]
                    .rfu_under_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_between_panels
                  : (result_data_selected.result_daily_data[i]
                    .rfu_between_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_agri_pv
                  : (result_data_selected.result_daily_data[i].rfu_agri_pv /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            RFU:
              selectedDailyRUUnite == "Fill (mm)"
                ? Math.round(
                  (result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                  100
                ) / 100
                : Math.round(
                  (((result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                    100) /
                    result.simulation.water_balance.ru_water) *
                  100
                ) / 100,
          });
      } else
        for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
          points.push({
            date: result_data_selected.result_daily_data[i].date,
            zt:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_ref
                  : (result_data_selected.result_daily_data[i].ru_ref /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_under_panels
                  : (result_data_selected.result_daily_data[i].ru_under_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_between_panels
                  : (result_data_selected.result_daily_data[i]
                    .ru_between_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_agri_pv
                  : (result_data_selected.result_daily_data[i].ru_agri_pv /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            RFU:
              selectedDailyRUUnite == "Fill (mm)"
                ? Math.round(
                  (result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                  100
                ) / 100
                : Math.round(
                  (((result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                    100) /
                    result.simulation.water_balance.ru_water) *
                  100
                ) / 100,
          });
      return points;
    }
    return null;
  }, [result, selectedDailyRUUnite]);

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const tableValues = useMemo(() => {
    if (
      !result ||
      !result.result_data.length ||
      !result.result_data[0] ||
      !result.result_data[0].result_daily_data ||
      !result.water_balance_analysis ||
      !result.water_balance_analysis.length
    )
      return null;
    return result.simulation.crop_for_water_balance.periods.map(
      (period: any, index: number) => {
        const values = getValueInCropPeriod(period);
        if (!values.length)
          return {
            name: period.name,
            STRESS_HYDRIQUE_ZT: 0,
            STRESS_HYDRIQUE_UNDER: 0,
            STRESS_HYDRIQUE_BETWEEN: 0,
            STRESS_HYDRIQUE_AGRIPV: 0,
          };
        return {
          name: period.name,
          STRESS_HYDRIQUE_ZT: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).control_zone,
          STRESS_HYDRIQUE_UNDER: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).under_panels,
          STRESS_HYDRIQUE_BETWEEN: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).between_panels,
          STRESS_HYDRIQUE_AGRIPV: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).agriPV,
        };
      }
    );
  }, [result]);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className={styled.optionContainer}>
            <div className={styled.uniteSelector}>
              <Select
                options={uniteDailyRUOptions}
                value={uniteDailyRUOptions.find(
                  (item) => item.value == selectedDailyRUUnite
                )}
                onChange={(e: any) => {
                  setSelectedDailyRUUnite(e.value);
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
            <div className={styled.chartTitle}>
              {"Available Water Stock"}
            </div>
            <div className={styled.downloadContainer}>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng("AvailableWaterStock")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(
                    daily_RU_points,
                    `${result.simulation.name} Available Water Stock (${selectedDailyRUUnite})`
                  )}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>
          </div>

          {daily_RU_points != null && <div id={"AvailableWaterStock"}>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                width={500}
                height={500}
                data={daily_RU_points}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  height={75}
                  dataKey="date"
                  interval={0}
                  tick={<CustomizedTick />}
                  ticks={[
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-01-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-02-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-03-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-04-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-05-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-06-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-07-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-08-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-09-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-10-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-11-01`,
                    `${new Date(
                      result.simulation.crop.periods[0].start_date
                    ).getFullYear()}-12-01`,
                  ]}
                />
                <YAxis>
                  <Label
                    fontSize={12}
                    style={{
                      textAnchor: "middle",
                    }}
                    position={"insideLeft"}
                    angle={270}
                    value={selectedDailyRUUnite}
                  />
                </YAxis>
                {result.simulation.crop_for_water_balance.periods.map(
                  (period: any, index: number) => (
                    <ReferenceArea
                      id={`ReferenceArea ${period.id}`}
                      x1={period.start_date}
                      x2={period.end_date}
                      fill={index % 2 ? "#333" : "#888"}
                      fillOpacity={0.1}
                    />
                  )
                )}
                <ReferenceArea
                  y2={daily_RU_points[0].RFU}
                  fill="red"
                  fillOpacity={0.1}
                />
                <Tooltip content={CustomTooltip} />
                <Legend
                      onClick={(o) => {
                        if (
                          !inactiveKeysDailyRU.find(
                            (inactiveKey) => inactiveKey == o.dataKey
                          )
                        )
                          setInactiveKeyDailyRU([
                            ...inactiveKeysDailyRU,
                            o.dataKey,
                          ]);
                        else {
                          var temp = inactiveKeysDailyRU;
                          temp.splice(
                            inactiveKeysDailyRU.findIndex(
                              (inactiveKey) => inactiveKey == o.dataKey
                            ),
                            1
                          );
                          setInactiveKeyDailyRU([...temp]);
                        }
                      }}
                    />
                <Line
                  dot={<></>}
                  type="monotone"
                  dataKey={"zt"}
                  name="Control Zone"
                  fill={"#AA6666"}
                  stroke={"#AA6666"}
                  unit={selectedDailyRUUnite == "Fill (mm)" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "zt"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  dot={<></>}
                  type="monotone"
                  dataKey={"under_panel"}
                  name="Under Panels"
                  fill={"#66AA66"}
                  stroke={"#66AA66"}
                  unit={selectedDailyRUUnite == "Fill (mm)" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "under_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  dot={<></>}
                  type="monotone"
                  dataKey={"between_panel"}
                  name="Between Panels"
                  fill={"#6666AA"}
                  stroke={"#6666AA"}
                  unit={selectedDailyRUUnite == "Fill (mm)" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "between_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  dot={<></>}
                  type="monotone"
                  dataKey={"agri_pv"}
                  name="AgriPV Zone"
                  fill={"#444444"}
                  stroke={"#444444"}
                  unit={selectedDailyRUUnite == "Fill (mm)" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "agri_pv"
                    )
                      ? true
                      : false
                  }
                />
                <Line
                  dot={<></>}
                  type="monotone"
                  dataKey={"RFU"}
                  name="RFU"
                  fill={"black"}
                  stroke={"black"}
                  unit={selectedDailyRUUnite == "Fill (mm)" ? "mm" : "%"}
                  hide={
                    inactiveKeysDailyRU.find(
                      (inactiveKey) => inactiveKey == "RFU"
                    )
                      ? true
                      : false
                  }
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>}
        </div>
      </div>
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            SimulationResultBodyMessages.hintChartToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng(
              "Table AvailableWaterStock"
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
        <TooltipMUI
          title={intl.formatMessage(
            WaterBalanceResultBodyMessages.hintChartToCsv
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToCsv(
              tableValues,
              `${result.simulation.name} ${!result.simulation.water_balance.irrigation ? intl.formatMessage(WaterBalanceResultBodyMessages.waterStressNumber) :
                intl.formatMessage(WaterBalanceResultBodyMessages.irrigationValue)} `
              // `${result.simulation.name} Available Water Stock (${selectedDailyRUUnite})`
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_file}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
        className={styled.dataTable}
        id="Table AvailableWaterStock">
        <thead>
          <tr>
            <th>
              <FormattedMessage {...CropCreateMessages.stageName} />
            </th>
            <th>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!result.simulation.water_balance.irrigation ? <FormattedMessage {...WaterBalanceResultBodyMessages.waterStressNumber} /> :
                  <FormattedMessage {...WaterBalanceResultBodyMessages.irrigationValue} />}
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.controlZoneSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!result.simulation.water_balance.irrigation ? <FormattedMessage {...WaterBalanceResultBodyMessages.waterStressNumber} /> :
                  <FormattedMessage {...WaterBalanceResultBodyMessages.irrigationValue} />}
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.underPanelsSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!result.simulation.water_balance.irrigation ? <FormattedMessage {...WaterBalanceResultBodyMessages.waterStressNumber} /> :
                  <FormattedMessage {...WaterBalanceResultBodyMessages.irrigationValue} />}
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!result.simulation.water_balance.irrigation ? <FormattedMessage {...WaterBalanceResultBodyMessages.waterStressNumber} /> :
                  <FormattedMessage {...WaterBalanceResultBodyMessages.irrigationValue} />}
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle} />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableValues != null && tableValues.map((item: any) => (
            <tr key={`tableIndex${item.name}`}>
              <td>{item.name}</td>
              <td>{item.STRESS_HYDRIQUE_ZT}</td>
              <td>{item.STRESS_HYDRIQUE_UNDER}</td>
              <td>{item.STRESS_HYDRIQUE_BETWEEN}</td>
              <td>{item.STRESS_HYDRIQUE_AGRIPV}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AvailableWaterStockContent;
