import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import AnalysisContent from "./AnalysisContent";
import AvailableWaterStockContent from "./AvailableWaterStockContent";
import WaterConsumptionContent from "./WaterConsumptionContent";
import SimulationConfigContent from "./SimulationConfigContent";
import IrradianceProductionContent from "./IrradianceProductionContent";
import WaterbalanceContent from "./WaterbalanceContent";
import ThermalStress from "./ThermalStress";
import GrasslandYieldContent from "./GrasslandYieldContent";
import moment from "moment";

class SimulationResultMenuProps {
  result: any;
}

const WaterBalanceResultMenu = ({ result }: SimulationResultMenuProps) => {
  const [itemList, setItemList] = useState([
    "Simulation Config",
    "Analysis",
    "Irradiance & Production",
    "Water Balance",
    // "Available Water Stock",
    // "Water Consumption",
    // "Grassland Yield",
    "Thermal Stress",
    // "Water Balance",
    // "Grassland Yield",
  ]);
  const [contents, setContents] = useState({

    ["Simulation Config"]: <SimulationConfigContent result={result} />,
    ["Analysis"]: <AnalysisContent result={result} />,
    ["Irradiance & Production"]: <IrradianceProductionContent result={result} />,
    ["Water Balance"]: <WaterbalanceContent result={result} />,
    ["Grassland Yield"]: <GrasslandYieldContent result={result} />,
    ["Thermal Stress"]: <ThermalStress result={result} />,



  });
  const [itemSelected, setItemSelected] = useState(itemList[0]);

  useEffect(() => {
    if (result.simulation.grassland_primary_yield_calculation && !itemList.find(title => title == "Grassland Yield"))
      setItemList([...itemList, "Grassland Yield"])
  }, [result])

  const onSelectItem = (item: string) => {
    setItemSelected(item);
    // setTimeout(
    //   () =>
    //     window.scroll({
    //       top: 9999999,
    //       behavior: "smooth",
    //     }),
    //   50
    // );
  };

  return (
    <div className={styled.main_container}>
      <div className={styled.item_list}>
        {itemList.map((value) => (
          <button
            id={"item" + value}
            onClick={(e) => onSelectItem(value)}
            className={clsx(styled.item, {
              [styled.active]: itemSelected === value,
            })}
          >
            {value}
          </button>
        ))}
      </div>
      <div className={styled.content}>
        {contents[itemSelected as keyof typeof contents] || (
          <>Content Not Implemented</>
        )}
      </div>
    </div>
  );
};

export default WaterBalanceResultMenu;
