import MainContent from "components/MainContent/MainContent";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import { Loader } from "components/common/loader/Loader";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBatchWaterBalanceSimulationResult, resetBatchWaterBalanceSimulationResult } from "store/batches_water_balance/actions";
import { getBatchWaterBalanceSimulationResultFiles, getBatchWaterBalanceSimulationResultImage } from "store/batches_water_balance/api";
import { getBatchWaterBalanceSimulationResultState } from "store/batches_water_balance/selectors";
import { getNavigationState } from "store/navigation/selectors";
import { downloadFile } from "utils/downloadFile";
import styled from "./styled.module.scss";
import { IPeriod } from "components/SimulationResultBody/SimulationResultBody";
import Button from "components/common/button/Button";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import { FormattedMessage } from "react-intl";
import CommonMessages from "components/common/CommonMessages";
import WaterBalanceResultBody from "components/WaterBalanceResultBody/WaterBalanceResultBody";

const REFRESH_DATA_TIME = 10000;

const BatchesWaterBalanceSimulationResult = () => {
  const dispatch = useDispatch();
  const { client_id, project_id, batch_water_balance_id, result_id, simulation_water_balance_id } =
    useParams();
  const result = useSelector(getBatchWaterBalanceSimulationResultState);
  const { batches_water_balance } = useSelector(getNavigationState);
  const [image, setImage] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod>(null);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isProgressSimulation = isPrepare || isRunningMain || isRunningPeriods;
  const isSuccessSimulation = result?.status === StatusesEnum.COMPLETED;

  const findFullName = (findId: number) => {
    for (const batch of batches_water_balance) {
      for (const result of batch.results) {
        if (result.simulations_water_balance) {
          const index = result.simulations_water_balance.findIndex(
            ({ id }: any) => id === findId
          );

          if (index !== -1) {
            return `${batch.name} - ${result.name} - ${result.simulations_water_balance[index].name}`;
          }
        }
      }
    }
    return result.name;
  };

  const options = useMemo(() => {
    return result?.result_data.map((item: any) => ({
      value: item.id,
      label: item.period.name,
    }));
  }, [result?.result_data]);

  const onSelect = (option: any) => {
    setSelectedPeriod(option);
  };

  const getResult = () => {
    dispatch(
      getBatchWaterBalanceSimulationResult({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: result_id,
        simulationWaterBalanceId: simulation_water_balance_id,
      })
    );
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getBatchWaterBalanceSimulationResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: batch_water_balance_id,
      resultId: result_id,
      simulationWaterBalanceId: simulation_water_balance_id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(result.id)}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  useEffect(() => {
    if (options?.length && isSuccessSimulation) {
      setSelectedPeriod(options[0]);
    }
  }, [options, isSuccessSimulation, batch_water_balance_id, result_id, simulation_water_balance_id]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressSimulation) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [result]);

  useEffect(() => {
    getResult();
  }, [client_id, project_id, simulation_water_balance_id, result_id, batch_water_balance_id]);

  useEffect(() => {
    return () => {
      dispatch(resetBatchWaterBalanceSimulationResult());
    };
  }, []);

  useEffect(() => {
    if (selectedPeriod !== null && isSuccessSimulation) {
      getBatchWaterBalanceSimulationResultImage({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch_water_balance_id,
        resultId: result_id,
        simulationWaterBalanceId: simulation_water_balance_id,
        imageId: selectedPeriod?.value,
      }).then((img) => setImage(img));
    }
  }, [selectedPeriod, isSuccessSimulation]);

  if (!result) return <Loader />;

  return (
    <MainContent
      header={
        <>
          <div className={styled.headerRow}>
            <h2 className={styled.title}>{findFullName(result.id)}</h2>
            <StatusLabel status={result?.status} />
          </div>

          {isSuccessSimulation && (
            <div className={styled.headerRow}>
              <Button
                iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                disabled={isFileLoading}
                isLoading={isFileLoading}
                onClick={onDownload}
              >
                <FormattedMessage {...CommonMessages.download} />
              </Button>
            </div>
          )}
        </>
      }
    >
      <WaterBalanceResultBody
        result={result}
        onSelect={onSelect}
        selectedPeriod={selectedPeriod}
        image={image}
      />
    </MainContent>
  );
};

export default BatchesWaterBalanceSimulationResult;
