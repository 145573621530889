import { useIntl } from "react-intl";
import parametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBatchesWaterBalanceParameters } from "store/batches_water_balance/selectors";
import { getBatchWaterBalanceParametersOfInterest } from "store/batches_water_balance/actions";

export type IInterestNameVariantsWaterBalance =
  | "weather_dataset_id"
  | "ETP_dataset_id"
  | "production_dataset_id";

export interface IInterestParamsWaterBalanceItem {
  category: "datasets";
  created_at: string;
  id: number;
  name: IInterestNameVariantsWaterBalance;
  type: "production_dataset" | "weather_dataset" | "ETP_dataset";
  updated_at: string;
  label: string;
}

export function useWaterBalanceParamsList() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector(getBatchesWaterBalanceParameters);

  useEffect(() => {
    dispatch(getBatchWaterBalanceParametersOfInterest());
  }, []);

  const formatList: IInterestParamsWaterBalanceItem[] = list.map(
    (item: Omit<IInterestParamsWaterBalanceItem, "label">) => ({
      ...item,
      label: intl.formatMessage(parametersInterestMessages[item.name]),
    })
  );

  return formatList;
}
