import BatchesWaterBalanceBody, { IBatchesWaterBalanceState } from "components/BatchesWaterBalanceBody/BatchesWaterBalanceBody";
import { frequencyOptions } from "components/WaterBalanceBody/WaterBalanceBody";
import { ROUTES } from "constants/routes";
import { FormikHelpers, useFormik } from "formik";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createBatchWaterBalance, runBatchWaterBalanceSimulation } from "store/batches_water_balance/actions";
import BatchesWaterBalanceSchema from "./BatchesWaterBalanceSchema";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import MainContent from "components/MainContent/MainContent";
import EditName from "components/EditName/EditName";
import BatchesWaterBalanceCreateMessages from "./BatchesWaterBalanceCreateMessages";
import CommonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import styled from "./styled.module.scss";
import { ReactComponent as Save } from "assets/icons/save.svg";

export const getDefaultValues = (intl: any): IBatchesWaterBalanceState => {
  return {
    name: intl.formatMessage(BatchesWaterBalanceCreateMessages.riskAnalysisUnnamed),
    base_simulation_water_balance_id: null,
    frequency: frequencyOptions[3].value,
    interests: [],
    rows: [],
  }
};

const BatchesWaterBalanceCreate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useUpdateNavigation();

  const onBack = () => {
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_CREATE.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  };

  const toEditPage = (batch_water_balance: any) => {
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", batch_water_balance.id)
    );
  };

  const toResultPage = (batch_water_balance: any, id: string) =>
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":batch_water_balance_id", batch_water_balance.id)
        .replace(":id", id)
    );

  const onCreate = (
    values: IBatchesWaterBalanceState,
    actions: FormikHelpers<IBatchesWaterBalanceState>
  ) => {
    dispatch(
      createBatchWaterBalance({
        ...values,
        clientId: client_id,
        projectId: project_id,
        callback: toEditPage,
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onCreate,
    validationSchema: BatchesWaterBalanceSchema(intl),
  });

  usePrompt(
    !isEqual(formik.values, getDefaultValues(intl)) && !formik.isSubmitting && !isLoading
  );

  const runSimulation = (batch: any) => {
    dispatch(
      runBatchWaterBalanceSimulation({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: batch.id as number,
        callback: (id: string) => toResultPage(batch, id),
      })
    );
  };

  const onRunSimulation = () => {
    setIsLoading(true);
    formik.validateForm().then((errors: any) => {
      Object.keys(errors).forEach((key) => formik.setFieldTouched(key, true));
    });

    if (formik.isValid) {
      dispatch(
        createBatchWaterBalance({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          callback: runSimulation,
        })
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
            autoFocus
            onFocus={(event) => {
              event.target.select();
            }}
              placeholder={intl.formatMessage(BatchesWaterBalanceCreateMessages.name)}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage {...CommonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <BatchesWaterBalanceBody formik={formik} onRunSimulation={onRunSimulation} />
      </MainContent>
    </form>
  );
};

export default BatchesWaterBalanceCreate;
