import { defineMessages } from "react-intl";

export default defineMessages({
  controlZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.controlZoneSubTitle",
    defaultMessage: "in Control Zone",
  },
  underPanelsSubTitle: {
    id: "WaterBalanceResultMenuMessages.underPanelsSubTitle",
    defaultMessage: "in Under Panels Zone",
  },
  betweenPanelsZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle",
    defaultMessage: "in Between Panels Zone",
  },
  agriPVZoneSubTitle: {
    id: "WaterBalanceResultMenuMessages.agriPVZoneSubTitle",
    defaultMessage: "in AgriPV Zone",
  },
  waterConsumptionPerStage: {
    id: "WaterBalanceResultMenuMessages.waterConsumptionPerStage",
    defaultMessage: "Water Consumption per stage (mm)",
  },
  waterDeficitPerStage: {
    id: "WaterBalanceResultMenuMessages.waterDeficitPerStage",
    defaultMessage: "Water Deficit per stage (mm)",
  },
});
