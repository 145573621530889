import styled from './styled.module.scss'
import ETPContent from "./ETPContent";
import IrradianceContent from "./IrradianceContent";
import ProductionContent from "./ProductionContent";

const IrradianceProductionContent = ({ result }: any) => {
	return <div className={styled.row}>
		<div style={{ width: "calc(50% - 1rem)" }}>
			<IrradianceContent result={result} />
		</div>
		<div className={styled.verticalLimiter} />
		<div style={{ width: "calc(50% - 1rem)" }}>
			<ProductionContent result={result} />
		</div>
	</div>


}

export default IrradianceProductionContent;