import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import moment from "moment";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";

const RUUnites = ["Fill (mm)", "Fill (%)"];

class IrradianceContentProps {
  result: any;
}

const WaterConsumptionContent = ({ result }: IrradianceContentProps) => {
  const intl = useIntl();

  const [selectedDailyWCUnite, setSelectedDailyWCUnite] = useState<
  "Water Consumption" | "Fraction"
>("Water Consumption");

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const period = result.simulation.crop_for_water_balance.periods.find(
        (period: any) =>
          new Date(period.start_date) <= new Date(label) &&
          new Date(label) <= new Date(period.end_date)
      );

      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{moment(label).format("DD MMM")}</p>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {period ? period.name : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const [inactiveKeysDailyWC, setInactiveKeyDailyWC] = useState(
    Array<string>()
  );
  const daily_wc_points = useMemo(() => {
    if (result && selectedDailyWCUnite) {
      const result_data_selected = result.result_data[0];
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          wc_zt:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_zt
                : 100) * 100
            ) / 100,
          wc_under_panel:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_under_panel
                : (result_data_selected.result_daily_data[i].wc_under_panel *
                    100) /
                  result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
          wc_between_panel:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_between_panel
                : (result_data_selected.result_daily_data[i].wc_between_panel *
                    100) /
                  result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
          wc_agri_pv:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_agri_pv
                : (result_data_selected.result_daily_data[i].wc_agri_pv * 100) /
                  result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedDailyWCUnite]);
  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const tableValues = useMemo(() => {
    if (
      !result ||
      !result.result_data.length ||
      !result.result_data[0] ||
      !result.result_data[0].result_daily_data ||
      !result.water_balance_analysis ||
      !result.water_balance_analysis.length
    )
      return null;
    return result.simulation.crop_for_water_balance.periods.map(
      (period: any, index: number) => {
        const values = getValueInCropPeriod(period);
        if (!values.length)
          return {
            name: period.name,
            control_zone: 0,
            under_panels: 0,
            between_panels: 0,
            agriPV: 0,
          };
        return {
          name: period.name,
          control_zone: Math.round(values.reduce((acc: number, item: any) => acc + item.wc_zt, 0)),
          under_panels: Math.round(values.reduce((acc: number, item: any) => acc + item.wc_under_panel, 0)),
          between_panels: Math.round(values.reduce((acc: number, item: any) => acc + item.wc_between_panel, 0)),
          agriPV: Math.round(values.reduce((acc: number, item: any) => acc + item.wc_agri_pv, 0)),
        };
      }
    );
  }, [result]);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className={styled.optionContainer}>
                <div className={styled.uniteSelector}></div>
                <div className={styled.chartTitle}>{"Water Consumption"}</div>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("WaterConsumptionChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                  <TooltipMUI
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintChartToCsv
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToCsv(
                        daily_wc_points,
                        `${result.simulation.name} Water Consumption`
                      )}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_file}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
              </div>
              <div id={"WaterConsumptionChart"}>
                <ResponsiveContainer width="100%" height={300}>
                  <ComposedChart
                    width={500}
                    height={500}
                    data={daily_wc_points!}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      height={75}
                      dataKey="date"
                      interval={0}
                      tick={<CustomizedTick />}
                      ticks={[
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-01-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-02-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-03-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-04-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-05-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-06-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-07-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-08-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-09-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-10-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-11-01`,
                        `${new Date(
                          result.simulation.crop.periods[0].start_date
                        ).getFullYear()}-12-01`,
                      ]}
                    />
                    <YAxis>
                      <Label
                        fontSize={12}
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideLeft"}
                        angle={270}
                        value={"Water consumption (mm)"}
                      />
                    </YAxis>
                    <Tooltip content={CustomTooltip} />
                    <Legend
                      style={{cursor: 'pointer'}}
                      onClick={(o) => {
                        if (
                          !inactiveKeysDailyWC.find(
                            (inactiveKey) => inactiveKey == o.dataKey
                          )
                        )
                          setInactiveKeyDailyWC([
                            ...inactiveKeysDailyWC,
                            o.dataKey,
                          ]);
                        else {
                          var temp = inactiveKeysDailyWC;
                          temp.splice(
                            inactiveKeysDailyWC.findIndex(
                              (inactiveKey) => inactiveKey == o.dataKey
                            ),
                            1
                          );
                          setInactiveKeyDailyWC([...temp]);
                        }
                      }}
                    />
                    {result.simulation.crop_for_water_balance.periods.map(
                      (period: any, index: number) => (
                        <ReferenceArea
                          id={period.id}
                          x1={period.start_date}
                          x2={period.end_date}
                          fill={index % 2 ? "#333" : "#888"}
                          fillOpacity={0.1}
                        />
                      )
                    )}
                    <Line
                      dot={<></>}
                      type="monotone"
                      dataKey={"wc_zt"}
                      name="Control Zone"
                      stroke={"#AA6666"}
                      fill="url(#splitColor)"
                      unit={"mm"}
                      hide={
                        inactiveKeysDailyWC.find(
                          (inactiveKey) => inactiveKey == "wc_zt"
                        )
                          ? true
                          : false
                      }
                    />

                    <Line
                      dot={<></>}
                      type="monotone"
                      dataKey={"wc_under_panel"}
                      name="Under Panels"
                      stroke={"#66AA66"}
                      fill="url(#splitColor)"
                      unit={"mm"}
                      hide={
                        inactiveKeysDailyWC.find(
                          (inactiveKey) => inactiveKey == "wc_under_panel"
                        )
                          ? true
                          : false
                      }
                    />
                    <Line
                      dot={<></>}
                      type="monotone"
                      dataKey={"wc_between_panel"}
                      name="Between Panels"
                      stroke={"#6666AA"}
                      fill="url(#splitColor)"
                      unit={"mm"}
                      hide={
                        inactiveKeysDailyWC.find(
                          (inactiveKey) => inactiveKey == "wc_between_panel"
                        )
                          ? true
                          : false
                      }
                    />
                    <Line
                      dot={<></>}
                      type="monotone"
                      dataKey={"wc_agri_pv"}
                      name="AgriPV Zone"
                      stroke={"#444444"}
                      fill="url(#splitColor)"
                      unit={"mm"}
                      hide={
                        inactiveKeysDailyWC.find(
                          (inactiveKey) => inactiveKey == "wc_agri_pv"
                        )
                          ? true
                          : false
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            SimulationResultBodyMessages.hintChartToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng(
              "Table AvailableWaterStock"
            )}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
        className={styled.dataTable}
        id="Table AvailableWaterStock">
        <thead>
          <tr>
            <th>
              <FormattedMessage {...CropCreateMessages.stageName} />
            </th>
            <th>
              <div style={{display: "flex", flexDirection: "column"}}>
                <FormattedMessage {...WaterBalanceResultMenuMessages.waterConsumptionPerStage} />
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.controlZoneSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{display: "flex", flexDirection: "column"}}>
                <FormattedMessage {...WaterBalanceResultMenuMessages.waterConsumptionPerStage} />
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.underPanelsSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{display: "flex", flexDirection: "column"}}>
                <FormattedMessage {...WaterBalanceResultMenuMessages.waterConsumptionPerStage} />
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle} />
                </div>
              </div>
            </th>
            <th>
              <div style={{display: "flex", flexDirection: "column"}}>
                <FormattedMessage {...WaterBalanceResultMenuMessages.waterConsumptionPerStage} />
                <div className={styled.subTitle}>
                  <FormattedMessage {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle} />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableValues != null && tableValues.map((item: any) => (
            <tr key={`tableIndex${item.name}`}>
              <td>{item.name}</td>
              <td>{item.control_zone}</td>
              <td>{item.under_panels}</td>
              <td>{item.between_panels}</td>
              <td>{item.agriPV}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WaterConsumptionContent;
