import { defineMessages } from "react-intl";

export default defineMessages({
  name: {
    id: "BatchesWaterBalanceCreateMessages.name",
    defaultMessage: "Risk Analysis Name",
  },
  riskAnalysisUnnamed: {
    id: "BatchesWaterBalanceCreateMessages.riskAnalysisUnnamed",
    defaultMessage: "Unnamed Risk Analysis",
  },
});
