import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Bar,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  Text,
  BarChart,
} from "recharts";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CustomTick from "components/CustomTick/CustomTick";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import clsx from "clsx";

class ThermalStressContentProps {
  result: any;
}

const ThermalStress = ({ result }: ThermalStressContentProps) => {
  const intl = useIntl();

  const CustomTooltipStress = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{label}</p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div
                  style={{
                    color: pld.stroke ? pld.stroke : pld.fill,
                  }}
                >
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const tablesDatas = result.thermal_stress
    .filter(
      (elem: any) => elem.period_name !== "___1periodAsYear-forWaterBalance___"
    )
    .map((period: any) => {
      return {
        period_name: period.period_name,
        control_zone_twenty_five_degres: period.control_zone_twenty_five_degres,
        control_zone_zero_degres: period.control_zone_zero_degres,
        agripv_twenty_five_degres: period.agripv_twenty_five_degres,
        agripv_zero_degres: period.agripv_zero_degres,
        period_days:
          Math.round(
            (new Date(period.period_end_date).getTime() -
              new Date(period.period_start_date).getTime()) /
            60000 /
            60 /
            24
          ) + 1,
      };
    });

  const tableDatasThermic = () => {
    const totalThermicDatas = tablesDatas.reduce(
      (acc: any, period_datas: any) => {
        if (period_datas.period_name === "___1periodAsYear-forWaterBalance___")
          return acc;

        acc.agripv_zero_degres += period_datas.agripv_zero_degres;
        acc.agripv_twenty_five_degres += period_datas.agripv_twenty_five_degres;
        acc.control_zone_zero_degres += period_datas.control_zone_zero_degres;
        (acc.control_zone_twenty_five_degres +=
          period_datas.control_zone_twenty_five_degres),
          (acc.period_days += period_datas.period_days);
        return acc;
      },
      {
        period_name: "Total",
        agripv_zero_degres: 0,
        agripv_twenty_five_degres: 0,
        control_zone_zero_degres: 0,
        control_zone_twenty_five_degres: 0,
        period_days: 0,
      }
    );

    return [...tablesDatas, totalThermicDatas];
  };

  return (
    <div className={styled.list}>
      {tablesDatas && tablesDatas.length > 0 && (
        <div className={styled.wrapper} style={{ display: "flex" }}>
          <div className={styled.chartContainer}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className={styled.optionContainer}>
                <div className={styled.uniteSelector}></div>
                <div className={styled.chartTitle}>
                  {intl.formatMessage(
                    SimulationResultBodyMessages.titleThermalStressFreeze
                  )}
                </div>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("FrostStressChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
              </div>
              <div id={"FrostStressChart"}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={tablesDatas}
                    width={500}
                    height={700}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 20,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={"period_name"}
                      tick={<CustomTick />}
                      height={75}
                      interval={0}
                    />
                    <YAxis yAxisId="left" interval={0} orientation="left">
                      <Label
                        fontSize={12}
                        style={{ textAnchor: "middle" }}
                        position={"insideLeft"}
                        angle={270}
                        value="DAYS"
                      />
                    </YAxis>
                    <Tooltip content={CustomTooltipStress} />
                    <Legend
                      payload={[
                        {
                          value: "Control Zone",
                          color: "#156082",
                        },
                        {
                          value: "AgriPV Zone",
                          color: "#0F9ED5",
                        },
                      ]}
                    />
                    <Bar
                      type="monotone"
                      dataKey={"control_zone_zero_degres"}
                      name="Control Zone"
                      yAxisId="left"
                      fill={"#156082"}
                      unit={"days"}
                    />
                    <Bar
                      type="monotone"
                      dataKey={"agripv_zero_degres"}
                      name="AgriPV Zone"
                      yAxisId="left"
                      fill={"#0F9ED5"}
                      unit={"days"}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className={styled.verticalLimiter} />

          <div className={styled.chartContainer}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className={styled.optionContainer}>
                <div className={styled.uniteSelector}></div>
                <div className={styled.chartTitle}>
                  {intl.formatMessage(
                    SimulationResultBodyMessages.titleThermalStressHot
                  )}
                </div>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("HeatStressChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
              </div>
              <div id={"HeatStressChart"}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={tablesDatas}
                    width={500}
                    height={500}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 20,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={"period_name"}
                      tick={<CustomTick />}
                      height={75}
                      interval={0}
                    />
                    <YAxis yAxisId="left" interval={0} orientation="left">
                      <Label
                        fontSize={12}
                        style={{ textAnchor: "middle" }}
                        position={"insideLeft"}
                        angle={270}
                        value="DAYS"
                      />
                    </YAxis>
                    <Tooltip content={CustomTooltipStress} />
                    <Legend
                      payload={[
                        {
                          value: "Control Zone",
                          color: "#FFC000",
                        },
                        {
                          value: "AgriPV Zone",
                          color: "#FF0000",
                        },
                      ]}
                    />
                    <Bar
                      type="monotone"
                      dataKey={"control_zone_twenty_five_degres"}
                      name="Control Zone"
                      yAxisId="left"
                      fill={"#FFC000"}
                      unit={"days"}
                    />
                    <Bar
                      type="monotone"
                      dataKey={"agripv_twenty_five_degres"}
                      name="AgriPV Zone"
                      yAxisId="left"
                      fill={"#FF0000"}
                      unit={"days"}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styled.verticalLimiter} />

      <div className={styled.tableContainer}>
        {tablesDatas && tablesDatas.length > 0 && (
          <div className={styled.tableContainer}>
            <div className={styled.downloadContainer}>
              <TooltipMUI
                title={intl.formatMessage(
                  SimulationResultBodyMessages.hintChartToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng("Table thermal stress")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  SimulationResultBodyMessages.hintChartToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(
                    tableDatasThermic(),
                    `${result.simulation.name} thermal stress`
                  )}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>
            <table className={styled.dataTable} id="Table thermal stress">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      {...SimulationResultBodyMessages.periodSimulation}
                    />
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.frostStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...SimulationResultBodyMessages.frostStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.controlZone}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.frostStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...SimulationResultBodyMessages.frostStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.ETPAgriPVZoneSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.heatStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...SimulationResultBodyMessages.heatStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.controlZone}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.heatStressHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...SimulationResultBodyMessages.heatStress}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.agriPVZone}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <FormattedMessage {...SimulationResultBodyMessages.days} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableDatasThermic().map(
                  (thermal_period: any, index: number) => (
                    <tr key={thermal_period.simulation_result_id}>
                      <td>
                        <span>{thermal_period.period_name}</span>
                      </td>
                      <td>{thermal_period.control_zone_zero_degres}</td>
                      <td>{thermal_period.agripv_zero_degres}</td>
                      <td>{thermal_period.control_zone_twenty_five_degres}</td>
                      <td>{thermal_period.agripv_twenty_five_degres}</td>
                      <td>{thermal_period.period_days}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThermalStress;

