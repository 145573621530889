import chartToCsv from "utils/chartToCsv";
import { useMemo, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "./styled.module.scss";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import CropsRenderList from "components/SimulationDropItem/CropsRenderList";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import simulationDropItemMessages from "components/SimulationDropItem/SimulationDropItemMessages";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import WaterBalanceResultBodyMessages from "./WaterBalanceResultBodyMessages";
import { default as LineSimulation } from "components/SimulationDropItem/Line";
import Spinner from "react-bootstrap/Spinner";
import Select from "components/common/select/Select";
import { SpanError } from "components/common/span-error/SpanError";
import { StatusesEnum } from "components/StatusLabel/StatusLabel";
import Button from "components/common/button/Button";
import SoilInformationsRenderList from "components/WaterBalanceDropItem/SoilInformationsRenderList";
import clsx from "clsx";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  ReferenceArea,
  Label,
  Text,
  BarChart,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";
import JSZip from "jszip";
import {
  getDatasetDailyChart,
  getDatasetMonthlyChart,
} from "store/datasets/api";
import { useParams } from "react-router-dom";
import moment from "moment";
import BatchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import AddModalPeriodCropsTableMessages from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTableMessages";
import CommonMessages from "components/common/CommonMessages";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import WaterBalanceResultMenu from "components/WaterBalanceResultMenu/WaterBalanceResultMenu";
import chartToPng from "utils/chartToPng";

interface IWaterBalanceResultBody {
  result: any;
  selectedPeriod: IPeriod;
  image: string;
  onSelect: (option: any) => void;
  onCancelWaterBalance?: () => void;
}

interface ISelectOption {
  value: string;
  label: string;
}

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type IPeriod = ISelectOption | null;

const RUUnites = ["Fill (mm)", "Fill (%)"];

function getEquidistantDates(crop: any, n: number) {
  const startDateList = crop.periods.map((period: any) => new Date(period.start_date))
  const endDateList = crop.periods.map((period: any) => new Date(period.end_date))
  const startDate = new Date(Math.min(...startDateList));
  const endDate = new Date(Math.max(...endDateList));
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDiff = end.getTime() - start.getTime();
  const interval = totalDiff / (n + 1);
  const dates = [];
  for (let i = 1; i <= n; i++) {
    const date = new Date(start.getTime() + interval * i);
    dates.push(moment(date).format('YYYY-MM-DD'));
  }

  return dates;
}

const WaterBalanceResultBody = ({
  result,
  selectedPeriod,
  image,
  onSelect,
  onCancelWaterBalance,
}: IWaterBalanceResultBody) => {
  const intl = useIntl();
  const [isCanceling, setIsCanceling] = useState(false);

  const { client_id, project_id } = useParams();
  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isProgressSimulation = isPrepare || isRunningMain || isRunningPeriods;
  const isSuccessSimulation = result?.status === StatusesEnum.COMPLETED;
  const isFailedSimulation = result?.status === StatusesEnum.FAILED;

  const [isStructureOpen, setIsStructureOpen] = useState(false);
  const [isSoilInformationOpen, setIsSoilInformation] = useState(false);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [isSteeringAlgorithmOpen, setIsSteeringAlgorithmOpen] = useState(false);
  const [isWeatherDatasetOpen, setIsWeatherDatasetOpen] = useState(false);
  const [isProductionDatasetOpen, setIsProductionDatasetOpen] = useState(false);
  const [isETPDatasetOpen, setIsETPDatasetOpen] = useState(false);

  const [isDownloadAllLoading, setIsDownloadAllLoading] = useState(false);

  const onDownloadAllWaterBalanceData = async () => {
    setIsDownloadAllLoading(true);

    const saveFile = (file: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    };

    const generateCsvContent = (data: any, replace: boolean = false) => {
      return Object.keys(data)
        .map((key) =>
          data[key] != undefined
            ? String(data[key]).replace(".", replace ? "," : ".")
            : undefined
        )
        .filter((item) => item != undefined)
        .join("\t");
    };

    const generateCsvHeader = (data: any) => {
      return Object.keys(data).join("\t");
    };

    const generateInputCsvContent = () => {
      const structureToDownload = {
        structure_name: result.simulation.structure.name,
        panels_number_x: result.simulation.structure.panels_number_x,
        panels_number_y: result.simulation.structure.panels_number_y,
        panel_x: result.simulation.structure.panel_x,
        panel_y: result.simulation.structure.panel_y,
        panel_height: result.simulation.structure.panel_height,
        initial_offset_x: result.simulation.structure.initial_offset_x,
        initial_offset_y: result.simulation.structure.initial_offset_y,
        panels_gap_x: result.simulation.structure.panels_gap_x,
        panels_gap_y: result.simulation.structure.panels_gap_y,
        field_size_x: result.simulation.structure.field_size_x,
        field_size_y: result.simulation.structure.field_size_y,
        panel_opacity: result.simulation.structure.panel_opacity,
        structure_type: result.simulation.structure.structure_type,
        static_angle: result.simulation.structure.static_angle,
        tracking_max_angle: result.simulation.structure.tracking_max_angle,
        backtracking: result.simulation.structure.backtracking,
      };

      const cropToDownload = {
        crop_name: result.simulation.crop_for_water_balance.name,
      };

      const periodsToDownload =
        result.simulation.crop_for_water_balance.periods.map(
          (period: {
            name: any;
            start_date: any;
            end_date: any;
            crop_coefficient: any;
            crop_height: any;
          }) => ({
            period_name: period.name,
            start_date: period.start_date,
            end_date: period.end_date,
            cultural_coefficient: period.crop_coefficient
              ? period.crop_coefficient
              : 0.6,
            crop_height: period.crop_height ? period.crop_height : 0,
          })
        );

      const soilInformationToDownload = {
        soil_information_name: result.simulation.water_balance.name,
        ru_water: result.simulation.water_balance.ru_water,
        rfu_water: result.simulation.water_balance.rfu_water,
        irrigation: result.simulation.water_balance.irrigation,
      };

      const datasetsToDownload = [
        result.simulation.weather_dataset,
        result.simulation.production_dataset,
        result.simulation.ETP_dataset,
      ]
        .map((item) =>
          item
            ? {
              dataset_name: item.name,
              dataset_filename: item.dataset_filename,
              dataset_type: item.dataset_type,
              timezone: item.timezone,
              frequency: item.frequency,
            }
            : undefined
        )
        .filter((item) => item);

      const simulationToDownload = {
        name: result.simulation.name,
        description: result.simulation.description,
        frequency: result.simulation.frequency,
        enable_max_scale: result.simulation.enable_max_scale,
        max_scale_value: result.simulation.max_scale_value,
        resolution: result.simulation.resolution,
      };

      const csvHeader =
        generateCsvHeader(structureToDownload) +
        "\t\t" +
        generateCsvHeader(cropToDownload) +
        "\t\t" +
        periodsToDownload
          .map((period: any) => generateCsvHeader(period) + "\t")
          .join() +
        "\t" +
        generateCsvHeader(soilInformationToDownload) +
        "\t\t" +
        datasetsToDownload
          .map((dataset: any) => generateCsvHeader(dataset) + "\t")
          .join() +
        "\t" +
        generateCsvHeader(simulationToDownload);
      const csvData =
        generateCsvContent(structureToDownload) +
        "\t\t" +
        generateCsvContent(cropToDownload) +
        "\t\t" +
        periodsToDownload
          .map((period: any) => generateCsvContent(period) + "\t")
          .join() +
        "\t" +
        generateCsvContent(soilInformationToDownload) +
        "\t\t" +
        datasetsToDownload
          .map((dataset: any) => generateCsvContent(dataset) + "\t")
          .join() +
        "\t" +
        generateCsvContent(simulationToDownload);
      return csvHeader + "\n" + csvData;
    };

    const generateDailyDataCsvContent = async () => {
      let csvHeader = "Date";

      //

      let csvData: string[] | null = null;
      if (result.simulation.weather_dataset) {
        await getDatasetDailyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.weather_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader +=
                "\tGlobal Horizontal Irradiance\tDiffuse Horizontal Irradiance\tDirect Normal Irradiance";
              csvData = data!.map((item: any) =>
                generateCsvContent({ ...item }, true)
              );
            }
          })
          .catch(() => { });
      }
      if (result.simulation.ETP_dataset) {
        await getDatasetDailyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.ETP_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader +=
                "\tAir Pressure\tAir Temperature\tInfrared Radiation\tPrecipitation\tRelative Humidity\tWind Velocity";
              if (csvData)
                csvData = data.map(
                  (item: any, index: number) =>
                    csvData![index] +
                    "\t" +
                    generateCsvContent({ ...item, date: undefined }, true)
                );
              else
                csvData = data!.map((item: any) =>
                  generateCsvContent({ ...item }, true)
                );
            }
          })
          .catch(() => { });
      }
      if (result.simulation.ETP_dataset) {
        await getDatasetDailyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.production_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader += "\tProductible tracker solaire";
              if (csvData)
                csvData = data.map(
                  (item: any, index: number) =>
                    csvData![index] +
                    "\t" +
                    generateCsvContent({ ...item, date: undefined }, true)
                );
              else
                csvData = data!.map((item: any) =>
                  generateCsvContent({ ...item }, true)
                );
            }
          })
          .catch(() => { });
      }
      csvHeader +=
        "\tIrradiance Control Zone\tIrradiance Under Panels\tIrradiance Between Panel\t Irradiance Agri PV\t ETP Control Zone\tETP Under Panels\tETP Between Panel\t ETP Agri PV\t Production\t Production sun tracking\t Water Consumption Control Zone\tWater Consumption Under Panels\tWater Consumption Between Panel\t Water Consumption Agri PV\tWater Deficit Control Zone\tWater Deficit Under Panels\tWater Deficit Between Panel\t Water Deficit Agri PV\tAvailable Water Stock Control Zone\tAvailable Water Stock Under Panels\tAvailable Water Stock Between Panel\t Available Water Stock Agri PV";
      if (csvData)
        csvData = one_period_irr_points!.map(
          (item, index) =>
            csvData![index] +
            "\t" +
            generateCsvContent({ ...item, date: undefined }, true)
        );
      else
        csvData = one_period_irr_points!.map((item) =>
          generateCsvContent({ ...item }, true)
        );
      csvData = one_period_ETP_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, date: undefined }, true)
      );
      csvData = one_period_prod_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, date: undefined }, true)
      );
      csvData = daily_wc_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, date: undefined }, true)
      );
      csvData = daily_wd_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, date: undefined }, true)
      );
      csvData = daily_RU_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, date: undefined, RFU: undefined }, true)
      );

      return csvHeader + "\n" + csvData.join("\n");
    };

    const generateMonthlyDataCsvContent = async () => {
      let csvData: string[] | null = null;
      let csvHeader = "Month";

      if (result.simulation.weather_dataset) {
        await getDatasetMonthlyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.weather_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader +=
                "\tGlobal Horizontal Irradiance\tDiffuse Horizontal Irradiance\tDirect Normal Irradiance";
              csvData = data!.map((item: any) =>
                generateCsvContent(
                  { ...item, month: month[item.month - 1] },
                  true
                )
              );
            }
          })
          .catch(() => { });
      }
      if (result.simulation.ETP_dataset) {
        await getDatasetMonthlyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.ETP_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader +=
                "\tAir Pressure\tAir Temperature\tInfrared Radiation\tPrecipitation\tRelative Humidity\tWind Velocity";
              if (csvData)
                csvData = data.map(
                  (item: any, index: number) =>
                    csvData![index] +
                    "\t" +
                    generateCsvContent({ ...item, month: undefined }, true)
                );
              else
                csvData = data!.map((item: any) =>
                  generateCsvContent(
                    { ...item, month: month[item.month - 1] },
                    true
                  )
                );
            }
          })
          .catch(() => { });
      }
      if (result.simulation.ETP_dataset) {
        await getDatasetMonthlyChart({
          clientId: client_id,
          projectId: project_id,
          dataset_id: result.simulation.production_dataset.id,
        })
          .then(({ data }) => {
            if (data) {
              csvHeader += "\tProductible tracker solaire";
              if (csvData)
                csvData = data.map(
                  (item: any, index: number) =>
                    csvData![index] +
                    "\t" +
                    generateCsvContent({ ...item, month: undefined }, true)
                );
              else
                csvData = data!.map((item: any) =>
                  generateCsvContent(
                    { ...item, month: month[item.month - 1] },
                    true
                  )
                );
            }
          })
          .catch(() => { });
      }

      csvHeader +=
        "\tIrradiance Control Zone\tIrradiance Under Panels\tIrradiance Between Panel\t Irradiance Agri PV\t ETP Control Zone\tETP Under Panels\tETP Between Panel\t ETP Agri PV\t Production\t Production sun tracking\t Water Consumption Control Zone\tWater Consumption Under Panels\tWater Consumption Between Panel\t Water Consumption Agri PV\tWater Deficit Control Zone\tWater Deficit Under Panels\tWater Deficit Between Panel\t Water Deficit Agri PV\tAvailable Water Stock Control Zone\tAvailable Water Stock Under Panels\tAvailable Water Stock Between Panel\t Available Water Stock Agri PV";
      if (csvData)
        csvData = monthly_irr_points!.map(
          (item, index) =>
            csvData![index] +
            "\t" +
            generateCsvContent({ ...item, month: undefined }, true)
        );
      else
        csvData = monthly_irr_points!.map((item) =>
          generateCsvContent({ ...item, month: month[item.month - 1] }, true)
        );

      csvData = monthly_ETP_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, month: undefined }, true)
      );
      csvData = monthly_prod_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, month: undefined }, true)
      );
      csvData = monthly_wc_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, month: undefined }, true)
      );
      csvData = monthly_wd_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, month: undefined }, true)
      );
      csvData = monthly_RU_points!.map(
        (item, index) =>
          csvData![index] +
          "\t" +
          generateCsvContent({ ...item, month: undefined }, true)
      );

      return csvHeader + "\n" + csvData.join("\n");
    };

    const csvContent =
      generateInputCsvContent() +
      "\n\n\n\n" +
      (await generateDailyDataCsvContent()) +
      "\n\n\n\n" +
      (await generateMonthlyDataCsvContent());
    const blob = new Blob(["\ufeff", csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], `${result.name} Data File.csv`, {
          type: "application/zip",
        });
        URL.revokeObjectURL(url);
        saveFile(file);
        setIsDownloadAllLoading(false);
      });

    return;
  };

  const options = useMemo(() => {
    return result?.result_data.map((item: any) => ({
      value: item.id,
      label: item.period.name,
    }));
  }, [result?.result_data]);

  const [selectedOnePeriodIrrUnite, setSelectedOnePeriodIrrUnite] = useState<
    "Irradiance" | "PAR" | "Fraction"
  >("Irradiance");
  const [selectedOnePeriodETPUnite, setSelectedOnePeriodETPUnite] = useState<
    "ETP Daily" | "Fraction"
  >("ETP Daily");
  const [selectedOnePeriodProdUnite, setSelectedOnePeriodProdUnite] = useState<
    "Production" | "Fraction"
  >("Production");
  const [selectedDailyWCUnite, setSelectedDailyWCUnite] = useState<
    "Water Consumption" | "Fraction"
  >("Water Consumption");
  const [selectedDailyWDUnite, setSelectedDailyWDUnite] =
    useState<"Water Deficit">("Water Deficit");
  const [selectedDailyRUUnite, setSelectedDailyRUUnite] = useState<
    "Fill (mm)" | "Fill (%)"
  >("Fill (mm)");

  const [selectedMonthlyIrrUnite, setSelectedMonthlyIrrUnite] = useState<
    "Irradiance" | "PAR" | "Fraction"
  >("Irradiance");
  const [selectedMonthlyETPUnite, setSelectedMonthlyETPUnite] = useState<
    "Cumulated ETP" | "Fraction"
  >("Cumulated ETP");
  const [selectedMonthlyProdUnite, setSelectedMonthlyProdUnite] = useState<
    "Production" | "Fraction" | "Daily Production"
  >("Production");
  const [selectedMonthlyWCUnite, setSelectedMonthlyWCUnite] = useState<
    "Water Consumption" | "Daily Water Consumption" | "Fraction"
  >("Water Consumption");
  const [selectedMonthlyWDUnite, setSelectedMonthlyWDUnite] = useState<
    "Water Deficit" | "Daily Water Deficit"
  >("Water Deficit");
  const [selectedMonthlyRUUnite, setSelectedMonthlyRUUnite] = useState<
    "Fill (mm)" | "Fill (%)"
  >("Fill (mm)");

  const [inactiveKeysOnePeriodIrr, setInactiveKeyOnePeriodIrr] = useState(
    Array<string>()
  );
  const one_period_irr_points = useMemo(() => {
    if (result && selectedOnePeriodIrrUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          witness_zone:
            Math.round(
              (selectedOnePeriodIrrUnite == "Irradiance"
                ? result_data_selected.result_daily_data[i].irr_zt
                : selectedOnePeriodIrrUnite == "PAR"
                  ? (1 / 2.1) * result_data_selected.result_daily_data[i].irr_zt
                  : (result_data_selected.result_daily_data[i].irr_zt * 100) /
                  result_data_selected.result_daily_data[i].irr_zt) * 100
            ) / 100,
          under_panel:
            Math.round(
              (selectedOnePeriodIrrUnite == "Irradiance"
                ? result_data_selected.result_daily_data[i].irr_under_panel
                : selectedOnePeriodIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  result_data_selected.result_daily_data[i].irr_under_panel
                  : (result_data_selected.result_daily_data[i].irr_under_panel *
                    100) /
                  result_data_selected.result_daily_data[i].irr_zt) * 100
            ) / 100,
          between_panel:
            Math.round(
              (selectedOnePeriodIrrUnite == "Irradiance"
                ? result_data_selected.result_daily_data[i].irr_between_panel
                : selectedOnePeriodIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  result_data_selected.result_daily_data[i].irr_between_panel
                  : (result_data_selected.result_daily_data[i].irr_between_panel *
                    100) /
                  result_data_selected.result_daily_data[i].irr_zt) * 100
            ) / 100,
          agriPV:
            Math.round(
              (selectedOnePeriodIrrUnite == "Irradiance"
                ? result_data_selected.result_daily_data[i].irr_agri_pv
                : selectedOnePeriodIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  result_data_selected.result_daily_data[i].irr_agri_pv
                  : (result_data_selected.result_daily_data[i].irr_agri_pv *
                    100) /
                  result_data_selected.result_daily_data[i].irr_zt) * 100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedOnePeriodIrrUnite, selectedPeriod]);
  const monthly_irr_points = useMemo(() => {
    if (result && selectedMonthlyIrrUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        points.push({
          month: month[index],
          witness_zone:
            Math.round(
              (selectedMonthlyIrrUnite == "Irradiance"
                ? arr.reduce(
                  (accumulator: number, item: { irr_zt: number }) =>
                    accumulator + item.irr_zt,
                  0
                ) / arr.length
                : selectedMonthlyIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  (arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length)
                  : ((arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          under_panel:
            Math.round(
              (selectedMonthlyIrrUnite == "Irradiance"
                ? arr.reduce(
                  (accumulator: number, item: { irr_under_panel: number }) =>
                    accumulator + item.irr_under_panel,
                  0
                ) / arr.length
                : selectedMonthlyIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  (arr.reduce(
                    (accumulator: number, item: { irr_under_panel: number }) =>
                      accumulator + item.irr_under_panel,
                    0
                  ) /
                    arr.length)
                  : ((arr.reduce(
                    (accumulator: number, item: { irr_under_panel: number }) =>
                      accumulator + item.irr_under_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          between_panel:
            Math.round(
              (selectedMonthlyIrrUnite == "Irradiance"
                ? arr.reduce(
                  (
                    accumulator: number,
                    item: { irr_between_panel: number }
                  ) => accumulator + item.irr_between_panel,
                  0
                ) / arr.length
                : selectedMonthlyIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  (arr.reduce(
                    (
                      accumulator: number,
                      item: { irr_between_panel: number }
                    ) => accumulator + item.irr_between_panel,
                    0
                  ) /
                    arr.length)
                  : ((arr.reduce(
                    (
                      accumulator: number,
                      item: { irr_between_panel: number }
                    ) => accumulator + item.irr_between_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          agriPV:
            Math.round(
              (selectedMonthlyIrrUnite == "Irradiance"
                ? arr.reduce(
                  (accumulator: number, item: { irr_agri_pv: number }) =>
                    accumulator + item.irr_agri_pv,
                  0
                ) / arr.length
                : selectedMonthlyIrrUnite == "PAR"
                  ? (1 / 2.1) *
                  (arr.reduce(
                    (accumulator: number, item: { irr_agri_pv: number }) =>
                      accumulator + item.irr_agri_pv,
                    0
                  ) /
                    arr.length)
                  : ((arr.reduce(
                    (accumulator: number, item: { irr_agri_pv: number }) =>
                      accumulator + item.irr_agri_pv,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { irr_zt: number }) =>
                      accumulator + item.irr_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
        });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyIrrUnite, selectedPeriod]);

  const [inactiveKeysOnePeriodETP, setInactiveKeyOnePeriodETP] = useState(
    Array<string>()
  );
  const one_period_ETP_points = useMemo(() => {
    if (result && selectedOnePeriodETPUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          witness_zone:
            Math.round(
              (selectedOnePeriodETPUnite == "ETP Daily"
                ? result_data_selected.result_daily_data[i].etp_zt
                : 100) * 100
            ) / 100,
          under_panel:
            Math.round(
              (selectedOnePeriodETPUnite == "ETP Daily"
                ? result_data_selected.result_daily_data[i].etp_under_panel
                : (result_data_selected.result_daily_data[i].etp_under_panel *
                  100) /
                result_data_selected.result_daily_data[i].etp_zt) * 100
            ) / 100,
          between_panel:
            Math.round(
              (selectedOnePeriodETPUnite == "ETP Daily"
                ? result_data_selected.result_daily_data[i].etp_between_panel
                : (result_data_selected.result_daily_data[i].etp_between_panel *
                  100) /
                result_data_selected.result_daily_data[i].etp_zt) * 100
            ) / 100,
          agriPV:
            Math.round(
              (selectedOnePeriodETPUnite == "ETP Daily"
                ? result_data_selected.result_daily_data[i].etp_agri_pv
                : (result_data_selected.result_daily_data[i].etp_agri_pv *
                  100) /
                result_data_selected.result_daily_data[i].etp_zt) * 100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedOnePeriodETPUnite, selectedPeriod]);
  const monthly_ETP_points = useMemo(() => {
    if (result && selectedMonthlyETPUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        points.push({
          month: month[index],
          witness_zone:
            Math.round(
              (selectedMonthlyETPUnite == "Cumulated ETP"
                ? arr.reduce(
                  (accumulator: number, item: { etp_zt: number }) =>
                    accumulator + item.etp_zt,
                  0
                ) / arr.length
                : selectedMonthlyETPUnite == "Fraction"
                  ? ((arr.reduce(
                    (accumulator: number, item: { etp_zt: number }) =>
                      accumulator + item.etp_zt,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { etp_zt: number }) =>
                      accumulator + item.etp_zt,
                    0
                  ) /
                    arr.length)
                  : 0) * 100
            ) / 100,
          under_panel:
            Math.round(
              (selectedMonthlyETPUnite == "Cumulated ETP"
                ? arr.reduce(
                  (accumulator: number, item: { etp_under_panel: number }) =>
                    accumulator + item.etp_under_panel,
                  0
                ) / arr.length
                : selectedMonthlyETPUnite == "Fraction"
                  ? ((arr.reduce(
                    (accumulator: number, item: { etp_under_panel: number }) =>
                      accumulator + item.etp_under_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { etp_zt: number }) =>
                      accumulator + item.etp_zt,
                    0
                  ) /
                    arr.length)
                  : 0) * 100
            ) / 100,
          between_panel:
            Math.round(
              (selectedMonthlyETPUnite == "Cumulated ETP"
                ? arr.reduce(
                  (
                    accumulator: number,
                    item: { etp_between_panel: number }
                  ) => accumulator + item.etp_between_panel,
                  0
                ) / arr.length
                : selectedMonthlyETPUnite == "Fraction"
                  ? ((arr.reduce(
                    (
                      accumulator: number,
                      item: { etp_between_panel: number }
                    ) => accumulator + item.etp_between_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { etp_zt: number }) =>
                      accumulator + item.etp_zt,
                    0
                  ) /
                    arr.length)
                  : 0) * 100
            ) / 100,
          agriPV:
            Math.round(
              (selectedMonthlyETPUnite == "Cumulated ETP"
                ? arr.reduce(
                  (accumulator: number, item: { etp_agri_pv: number }) =>
                    accumulator + item.etp_agri_pv,
                  0
                ) / arr.length
                : selectedMonthlyETPUnite == "Fraction"
                  ? ((arr.reduce(
                    (accumulator: number, item: { etp_agri_pv: number }) =>
                      accumulator + item.etp_agri_pv,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { etp_zt: number }) =>
                      accumulator + item.etp_zt,
                    0
                  ) /
                    arr.length)
                  : 0) * 100
            ) / 100,
        });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyETPUnite, selectedPeriod]);

  const [inactiveKeysOnePeriodProd, setInactiveKeyOnePeriodProd] = useState(
    Array<string>()
  );
  const one_period_prod_points = useMemo(() => {
    if (result && selectedOnePeriodProdUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          production_max:
            Math.round(
              (selectedOnePeriodProdUnite == "Production"
                ? result_data_selected.result_daily_data[i].production_max
                : 100) * 100
            ) / 100,
          production:
            Math.round(
              (selectedOnePeriodProdUnite == "Production"
                ? result_data_selected.result_daily_data[i].production
                : (result_data_selected.result_daily_data[i].production * 100) /
                result_data_selected.result_daily_data[i].production_max) *
              100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedOnePeriodProdUnite, selectedPeriod]);
  const monthly_prod_points = useMemo(() => {
    if (result && selectedMonthlyProdUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        points.push({
          month: month[index],
          production_max:
            Math.round(
              (selectedMonthlyProdUnite == "Production"
                ? arr.reduce(
                  (accumulator: number, item: { production_max: number }) =>
                    accumulator + item.production_max,
                  0
                )
                : selectedMonthlyProdUnite == "Daily Production"
                  ? arr.reduce(
                    (accumulator: number, item: { production_max: number }) =>
                      accumulator + item.production_max,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { production_max: number }) =>
                      accumulator + item.production_max,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { production_max: number }) =>
                      accumulator + item.production_max,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          production:
            Math.round(
              (selectedMonthlyProdUnite == "Production"
                ? arr.reduce(
                  (accumulator: number, item: { production: number }) =>
                    accumulator + item.production,
                  0
                )
                : selectedMonthlyProdUnite == "Daily Production"
                  ? arr.reduce(
                    (accumulator: number, item: { production: number }) =>
                      accumulator + item.production,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { production: number }) =>
                      accumulator + item.production,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { production_max: number }) =>
                      accumulator + item.production_max,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
        });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyProdUnite, selectedPeriod]);

  const [inactiveKeysDailyWC, setInactiveKeyDailyWC] = useState(
    Array<string>()
  );
  const daily_wc_points = useMemo(() => {
    if (result && selectedDailyWCUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          wc_zt:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_zt
                : 100) * 100
            ) / 100,
          wc_under_panel:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_under_panel
                : (result_data_selected.result_daily_data[i].wc_under_panel *
                  100) /
                result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
          wc_between_panel:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_between_panel
                : (result_data_selected.result_daily_data[i].wc_between_panel *
                  100) /
                result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
          wc_agri_pv:
            Math.round(
              (selectedDailyWCUnite == "Water Consumption"
                ? result_data_selected.result_daily_data[i].wc_agri_pv
                : (result_data_selected.result_daily_data[i].wc_agri_pv * 100) /
                result_data_selected.result_daily_data[i].wc_zt) * 100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedDailyWCUnite, selectedPeriod]);
  const monthly_wc_points = useMemo(() => {
    if (result && selectedMonthlyWCUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        points.push({
          month: month[index],
          wc_zt:
            Math.round(
              (selectedMonthlyWCUnite == "Water Consumption"
                ? arr.reduce(
                  (accumulator: number, item: { wc_zt: number }) =>
                    accumulator + item.wc_zt,
                  0
                )
                : selectedMonthlyWCUnite == "Daily Water Consumption"
                  ? arr.reduce(
                    (accumulator: number, item: { wc_zt: number }) =>
                      accumulator + item.wc_zt,
                    0
                  ) / arr.length
                  : 0) * 100
            ) / 100,
          wc_under_panel:
            Math.round(
              (selectedMonthlyWCUnite == "Water Consumption"
                ? arr.reduce(
                  (accumulator: number, item: { wc_under_panel: number }) =>
                    accumulator + item.wc_under_panel,
                  0
                )
                : selectedMonthlyWCUnite == "Daily Water Consumption"
                  ? arr.reduce(
                    (accumulator: number, item: { wc_under_panel: number }) =>
                      accumulator + item.wc_under_panel,
                    0
                  ) / arr.length
                  : 0) * 100
            ) / 100,
          wc_between_panel:
            Math.round(
              (selectedMonthlyWCUnite == "Water Consumption"
                ? arr.reduce(
                  (accumulator: number, item: { wc_between_panel: number }) =>
                    accumulator + item.wc_between_panel,
                  0
                )
                : selectedMonthlyWCUnite == "Daily Water Consumption"
                  ? arr.reduce(
                    (accumulator: number, item: { wc_between_panel: number }) =>
                      accumulator + item.wc_between_panel,
                    0
                  ) / arr.length
                  : 0) * 100
            ) / 100,
          wc_agri_pv:
            Math.round(
              (selectedMonthlyWCUnite == "Water Consumption"
                ? arr.reduce(
                  (accumulator: number, item: { wc_agri_pv: number }) =>
                    accumulator + item.wc_agri_pv,
                  0
                )
                : selectedMonthlyWCUnite == "Daily Water Consumption"
                  ? arr.reduce(
                    (accumulator: number, item: { wc_agri_pv: number }) =>
                      accumulator + item.wc_agri_pv,
                    0
                  ) / arr.length
                  : 0) * 100
            ) / 100,
        });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyWCUnite, selectedPeriod]);

  const [inactiveKeysDailyWD, setInactiveKeyDailyWD] = useState(
    Array<string>()
  );
  const daily_wd_points = useMemo(() => {
    if (result && selectedDailyWDUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
        points.push({
          date: result_data_selected.result_daily_data[i].date,
          wd_zt:
            Math.round(
              (selectedDailyWDUnite == "Water Deficit"
                ? result_data_selected.result_daily_data[i].wd_zt
                : 100) * 100
            ) / 100,
          wd_under_panel:
            Math.round(
              (selectedDailyWDUnite == "Water Deficit"
                ? result_data_selected.result_daily_data[i].wd_under_panel
                : (result_data_selected.result_daily_data[i].wd_under_panel *
                  100) /
                result_data_selected.result_daily_data[i].wd_zt) * 100
            ) / 100,
          wd_between_panel:
            Math.round(
              (selectedDailyWDUnite == "Water Deficit"
                ? result_data_selected.result_daily_data[i].wd_between_panel
                : (result_data_selected.result_daily_data[i].wd_between_panel *
                  100) /
                result_data_selected.result_daily_data[i].wd_zt) * 100
            ) / 100,
          wd_agri_pv:
            Math.round(
              (selectedDailyWDUnite == "Water Deficit"
                ? result_data_selected.result_daily_data[i].wd_agri_pv
                : (result_data_selected.result_daily_data[i].wd_agri_pv * 100) /
                result_data_selected.result_daily_data[i].wd_zt) * 100
            ) / 100,
        });
      return points;
    }
    return null;
  }, [result, selectedDailyWDUnite, selectedPeriod]);
  const monthly_wd_points = useMemo(() => {
    if (result && selectedMonthlyWDUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        points.push({
          month: month[index],
          wd_zt:
            Math.round(
              (selectedMonthlyWDUnite == "Water Deficit"
                ? arr.reduce(
                  (accumulator: number, item: { wd_zt: number }) =>
                    accumulator + item.wd_zt,
                  0
                )
                : selectedMonthlyWDUnite == "Daily Water Deficit"
                  ? arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          wd_under_panel:
            Math.round(
              (selectedMonthlyWDUnite == "Water Deficit"
                ? arr.reduce(
                  (accumulator: number, item: { wd_under_panel: number }) =>
                    accumulator + item.wd_under_panel,
                  0
                )
                : selectedMonthlyWDUnite == "Daily Water Deficit"
                  ? arr.reduce(
                    (accumulator: number, item: { wd_under_panel: number }) =>
                      accumulator + item.wd_under_panel,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { wd_under_panel: number }) =>
                      accumulator + item.wd_under_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          wd_between_panel:
            Math.round(
              (selectedMonthlyWDUnite == "Water Deficit"
                ? arr.reduce(
                  (accumulator: number, item: { wd_between_panel: number }) =>
                    accumulator + item.wd_between_panel,
                  0
                )
                : selectedMonthlyWDUnite == "Daily Water Deficit"
                  ? arr.reduce(
                    (accumulator: number, item: { wd_between_panel: number }) =>
                      accumulator + item.wd_between_panel,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { wd_between_panel: number }) =>
                      accumulator + item.wd_between_panel,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
          wd_agri_pv:
            Math.round(
              (selectedMonthlyWDUnite == "Water Deficit"
                ? arr.reduce(
                  (accumulator: number, item: { wd_agri_pv: number }) =>
                    accumulator + item.wd_agri_pv,
                  0
                )
                : selectedMonthlyWDUnite == "Daily Water Deficit"
                  ? arr.reduce(
                    (accumulator: number, item: { wd_agri_pv: number }) =>
                      accumulator + item.wd_agri_pv,
                    0
                  ) / arr.length
                  : ((arr.reduce(
                    (accumulator: number, item: { wd_agri_pv: number }) =>
                      accumulator + item.wd_agri_pv,
                    0
                  ) /
                    arr.length) *
                    100) /
                  (arr.reduce(
                    (accumulator: number, item: { wd_zt: number }) =>
                      accumulator + item.wd_zt,
                    0
                  ) /
                    arr.length)) * 100
            ) / 100,
        });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyWDUnite, selectedPeriod]);

  const [inactiveKeysDailyRU, setInactiveKeyDailyRU] = useState(
    Array<string>()
  );
  const daily_RU_points = useMemo(() => {
    if (result && selectedDailyRUUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      if (result.simulation.water_balance.irrigation) {
        for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
          points.push({
            date: result_data_selected.result_daily_data[i].date,
            zt:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_ref
                  : (result_data_selected.result_daily_data[i].rfu_ref /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_under_panels
                  : (result_data_selected.result_daily_data[i]
                    .rfu_under_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_between_panels
                  : (result_data_selected.result_daily_data[i]
                    .rfu_between_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].rfu_agri_pv
                  : (result_data_selected.result_daily_data[i].rfu_agri_pv /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            RFU:
              selectedDailyRUUnite == "Fill (mm)"
                ? Math.round(
                  (result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                  100
                ) / 100
                : Math.round(
                  (((result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                    100) /
                    result.simulation.water_balance.ru_water) *
                  100
                ) / 100,
          });
      } else
        for (let i = 0; i < result_data_selected.result_daily_data.length; i++)
          points.push({
            date: result_data_selected.result_daily_data[i].date,
            zt:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_ref
                  : (result_data_selected.result_daily_data[i].ru_ref /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_under_panels
                  : (result_data_selected.result_daily_data[i].ru_under_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_between_panels
                  : (result_data_selected.result_daily_data[i]
                    .ru_between_panels /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedDailyRUUnite == "Fill (mm)"
                  ? result_data_selected.result_daily_data[i].ru_agri_pv
                  : (result_data_selected.result_daily_data[i].ru_agri_pv /
                    result.simulation.water_balance.ru_water) *
                  100) * 100
              ) / 100,
            RFU:
              selectedDailyRUUnite == "Fill (mm)"
                ? Math.round(
                  (result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                  100
                ) / 100
                : Math.round(
                  (((result.simulation.water_balance.ru_water -
                    result.simulation.water_balance.rfu_water) *
                    100) /
                    result.simulation.water_balance.ru_water) *
                  100
                ) / 100,
          });
      return points;
    }
    return null;
  }, [result, selectedDailyRUUnite, selectedPeriod]);
  const monthly_RU_points = useMemo(() => {
    if (result && selectedMonthlyRUUnite && selectedPeriod) {
      const result_data_selected = result.result_data.find(
        (result_data: any) => result_data.id == selectedPeriod.value
      );
      if (
        !result_data_selected ||
        !result_data_selected.result_daily_data.length
      )
        return null;
      let points = Array();
      for (let index = 0; index < 12; index++) {
        const arr = result_data_selected.result_daily_data.filter(
          (item: { date: string | number | Date }) =>
            new Date(item.date).getMonth() == index
        );
        if (result.simulation.water_balance.irrigation) {
          points.push({
            month: month[index],
            zt:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (accumulator: number, item: { rfu_ref: number }) =>
                      accumulator + item.rfu_ref,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (accumulator: number, item: { rfu_ref: number }) =>
                      accumulator + item.rfu_ref,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (
                      accumulator: number,
                      item: { rfu_under_panels: number }
                    ) => accumulator + item.rfu_under_panels,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (
                      accumulator: number,
                      item: { rfu_under_panels: number }
                    ) => accumulator + item.rfu_under_panels,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (
                      accumulator: number,
                      item: { rfu_between_panels: number }
                    ) => accumulator + item.rfu_between_panels,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (
                      accumulator: number,
                      item: { rfu_between_panels: number }
                    ) => accumulator + item.rfu_between_panels,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (accumulator: number, item: { rfu_agri_pv: number }) =>
                      accumulator + item.rfu_agri_pv,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (accumulator: number, item: { rfu_agri_pv: number }) =>
                      accumulator + item.rfu_agri_pv,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
          });
        } else
          points.push({
            month: month[index],
            zt:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (accumulator: number, item: { ru_ref: number }) =>
                      accumulator + item.ru_ref,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (accumulator: number, item: { ru_ref: number }) =>
                      accumulator + item.ru_ref,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            under_panel:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (
                      accumulator: number,
                      item: { ru_under_panels: number }
                    ) => accumulator + item.ru_under_panels,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (
                      accumulator: number,
                      item: { ru_under_panels: number }
                    ) => accumulator + item.ru_under_panels,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            between_panel:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (
                      accumulator: number,
                      item: { ru_between_panels: number }
                    ) => accumulator + item.ru_between_panels,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (
                      accumulator: number,
                      item: { ru_between_panels: number }
                    ) => accumulator + item.ru_between_panels,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            agri_pv:
              Math.round(
                (selectedMonthlyRUUnite == "Fill (mm)"
                  ? arr.reduce(
                    (accumulator: number, item: { ru_agri_pv: number }) =>
                      accumulator + item.ru_agri_pv,
                    0
                  ) / arr.length
                  : arr.reduce(
                    (accumulator: number, item: { ru_agri_pv: number }) =>
                      accumulator + item.ru_agri_pv,
                    0
                  ) /
                  arr.length /
                  result.simulation.water_balance.rfu_water) * 100
              ) / 100,
            RFU:
              Math.round(result.simulation.water_balance.rfu_water * 100) / 100,
          });
      }
      return points;
    }
    return null;
  }, [result, selectedMonthlyRUUnite, selectedPeriod]);

  const getValueInCropPeriod = (period: any) => {
    const start_date = new Date(period.start_date);
    const end_date = new Date(period.end_date);
    if (
      !result.result_data.length &&
      !result.result_data[0] &&
      !result.result_data[0].result_daily_data
    )
      return [];
    return result.result_data[0].result_daily_data.filter(
      (data: any) =>
        start_date <= new Date(data.date) && new Date(data.date) <= end_date
    );
  };

  const calculateRainPerPeriod = (period: any) => {
    if (!result.simulation.ETP_dataset)
      return "Simulation Doesn't has ETP dataset.";
    else {
      const start_period = period.start_date;
      const end_period = period.end_date;

      const precipitation = result.simulation.ETP_dataset.dataset_data.reduce(
        (acc: number, val: any) => {
          if (
            start_period <= val.date &&
            end_period >= val.date &&
            val.month === null
          )
            acc += val.precipitation;
          return acc;
        },
        0
      );
      return Math.round(precipitation);
    }
  };


  const calculateWaterConsumptionSimulation = (
    result_daily_data: any,
    period: any
  ) => {
    const start_period = new Date(period.start_date);
    const end_period = new Date(period.end_date);

    const sorted_tag = result_daily_data.filter(
      (data: any) =>
        start_period <= new Date(data.date) && new Date(data.date) <= end_period
    );
    const wc_agri_pv = sorted_tag.reduce(
      (acc: number, { wc_agri_pv }: any) => acc + wc_agri_pv,
      0
    );
    // return Math.round(wc_agri_pv)
    return Math.round(wc_agri_pv);
  };

  const tableValues = useMemo(() => {
    if (
      !result ||
      !result.result_data.length ||
      !result.result_data[0] ||
      !result.result_data[0].result_daily_data ||
      !result.water_balance_analysis ||
      !result.water_balance_analysis.length
    )
      return null;
    return result.simulation.crop_for_water_balance.periods.map(
      (period: any, index: number) => {
        const values = getValueInCropPeriod(period);
        if (
          !values.length ||
          !result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          )
        )
          return {
            IRR_FRACTION: 0,
            DAILY_PAR: 0,
            WATER_CONSUMPTION: 0,
            STRESS_HYDRIQUE_ZT: 0,
            STRESS_HYDRIQUE_AGRIPV: 0,
            RAIN: 0,
          };
        return {
          IRR_FRACTION:
            Math.round(
              (((values.reduce(
                (acc: number, data: any) => acc + data.irr_agri_pv,
                0
              ) /
                values.length) *
                100) /
                (values.reduce(
                  (acc: number, data: any) => acc + data.irr_zt,
                  0
                ) /
                  values.length)) *
              10
            ) / 10,
          DAILY_PAR: Math.round(
            values.reduce(
              (acc: number, data: any) => acc + (1 / 2.1) * data.irr_agri_pv,
              0
            ) / values.length
          ),
          WATER_CONSUMPTION: calculateWaterConsumptionSimulation(
            result.result_data[0].result_daily_data,
            period
          ),
          STRESS_HYDRIQUE_ZT: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ) ? result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).control_zone : 0,
          STRESS_HYDRIQUE_AGRIPV: result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ) ? result.water_balance_analysis.find(
            (analysis: any) =>
              analysis.period_name == period.name &&
              analysis.period_start_date == period.start_date &&
              analysis.period_end_date == period.end_date
          ).agriPV : 0,
          RAIN: calculateRainPerPeriod(period),
        };
      }
    );
  }, [result]);

  const getRowBackgroundColor = (value: number, type: string, period?: any) => {
    const sortedData = [...tableValues.map((val: any) => val[type])].sort(
      (a, b) => a - b
    );
    var median,
      min,
      max = 0;
    if (type == "STRESS_HYDRIQUE_ZT" || type == "STRESS_HYDRIQUE_AGRIPV") {
      const number_of_days =
        (new Date(period.end_date).getTime() -
          new Date(period.start_date).getTime()) /
        (1000 * 3600 * 24);

      median = Math.floor(number_of_days / 2);
      min = 0;
      max = number_of_days;
    } else {
      const middle = Math.floor(sortedData.length / 2);
      median =
        sortedData.length % 2 === 0
          ? (sortedData[middle - 1] + sortedData[middle]) / 2
          : sortedData[middle];

      min = sortedData[0];
      max = sortedData[sortedData.length - 1];
    }

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (type == "WATER_CONSUMPTION" || type == "RAIN") {
      return "white";
    }

    if (type == "STRESS_HYDRIQUE_ZT" || type == "STRESS_HYDRIQUE_AGRIPV")
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const uniteDailyRUOptions = useMemo(() => {
    return RUUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [RUUnites]);

  const currentProgress = useMemo(() => {
    const sumPeriodsPath = result?.result_data?.reduce(
      (a: any, { current, length, status }: any) => {
        if (status === StatusesEnum.COMPLETED) {
          a += length;
        } else {
          a += current;
        }

        return a;
      },
      0
    );

    const mainPath =
      isRunningMain || isPrepare ? result.current : result.length;

    return (mainPath + sumPeriodsPath) / (result.total_length / 100) || 0;
  }, [result]);

  const calculatePeriod = (period: any) => {
    if (period.status === "enqueued") return "Waiting...";
    if (period.status === "completed") return "Completed";
    return `${(period.current / (period.length / 100)).toFixed(1)} %`;
  };

  const onCancel = () => {
    if (onCancelWaterBalance) onCancelWaterBalance();
    setIsCanceling(true);
  };

  const tablesValues = useMemo(() => {
    if (!isSuccessSimulation && result)
      return null

    return {
      ZONE_CULTIVABLE:
        Math.round(
          (result.simulation.structure.structure_type == "fixed"
            ? (result.simulation.structure.panels_gap_y -
              result.simulation.structure.no_crop_zone) /
            result.simulation.structure.panels_gap_y
            : (result.simulation.structure.panels_gap_x -
              result.simulation.structure.no_crop_zone) /
            result.simulation.structure.panels_gap_x) * 10000
        ) / 100,
      TAUX_DE_COUVERTURE:
        Math.round(
          (result.simulation.structure.structure_type == "fixed"
            ? (result.simulation.structure.panel_y *
              Math.cos(
                (result.simulation.structure.static_angle *
                  Math.PI) /
                180
              )) /
            result.simulation.structure.panels_gap_y
            : result.simulation.structure.panel_x /
            result.simulation.structure.panels_gap_x) * 1000
        ) / 10,
      PUISSANCE_INSTALLEE: Math.round(
        (10000 / 2.85) *
        ((result.simulation.structure.panel_x *
          result.simulation.structure.panel_y) /
          (result.simulation.structure.panels_gap_x *
            result.simulation.structure.panels_gap_y)) *
        0.55
      ),
    }

  }, [result])

  const CustomizedTickStress = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <Text
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={8}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {payload.value}
        </Text>
      </g>
    );
  };

  const CustomTooltipStress = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{label}</p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke ? pld.stroke : pld.fill }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const tablesDatas = result.thermal_stress
    .filter(
      (elem: any) => elem.period_name !== "___1periodAsYear-forWaterBalance___"
    )
    .map((period: any) => {
      return {
        period_name: period.period_name,
        control_zone_twenty_five_degres: period.control_zone_twenty_five_degres,
        control_zone_zero_degres: period.control_zone_zero_degres,
        agripv_twenty_five_degres: period.agripv_twenty_five_degres,
        agripv_zero_degres: period.agripv_zero_degres,
        period_days:
          Math.round(
            (new Date(period.period_end_date).getTime() -
              new Date(period.period_start_date).getTime()) /
            60000 /
            60 /
            24
          ) + 1,
      };
    });

  const tableDatasThermic = () => {
    const totalThermicDatas = tablesDatas.reduce(
      (acc: any, period_datas: any) => {
        if (period_datas.period_name === "___1periodAsYear-forWaterBalance___")
          return acc;

        acc.agripv_zero_degres += period_datas.agripv_zero_degres;
        acc.agripv_twenty_five_degres += period_datas.agripv_twenty_five_degres;
        acc.control_zone_zero_degres += period_datas.control_zone_zero_degres;
        (acc.control_zone_twenty_five_degres +=
          period_datas.control_zone_twenty_five_degres),
          (acc.period_days += period_datas.period_days);
        return acc;
      },
      {
        period_name: "Total",
        agripv_zero_degres: 0,
        agripv_twenty_five_degres: 0,
        control_zone_zero_degres: 0,
        control_zone_twenty_five_degres: 0,
        period_days: 0,
      }
    );

    return [...tablesDatas, totalThermicDatas];
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={styled.simuEssenceContainer}>
          <div className={clsx(styled.simulationResultInfoContainer, { [styled.onProgress]: isProgressSimulation })}>
            <div className={clsx(styled.simulationResultImage, { [styled.onProgress]: isProgressSimulation })}>
              <h3 className={styled.statusTitle}>
                {isProgressSimulation ? (
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.simulationInProgress}
                  />
                ) : (
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.simulationResults}
                  />
                )}
              </h3>
              {isSuccessSimulation &&
                <div className={styled.waitContainer}>
                  <img
                    className={styled.resultImage}
                    src={image}
                    alt="Simulation result image"
                  />
                </div>
              }
              {isProgressSimulation && (
                <>
                  <div className={styled.progressBarContainer}>
                    <div className={styled.waitItem}>
                      <Spinner animation="border" className={styled.spinner} />
                      <p className={styled.inProgress}>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.inProgress}
                          values={{
                            value: isRunningPeriods ? 2 : 1,
                          }}
                        />
                      </p>

                      {!isNaN(currentProgress) && (
                        <>
                          <ProgressBar
                            striped
                            now={currentProgress}
                            className={styled.progressBar}
                          />
                          <h5 className={styled.progressBarLabel}>
                            {currentProgress.toFixed(0)} %
                          </h5>

                          <ul className={styled.list}>
                            {isRunningPeriods ? (
                              result.result_data.map((periodResult: any) => (
                                <LineSimulation
                                  title={periodResult.period.name}
                                  value={calculatePeriod(periodResult)}
                                />
                              ))
                            ) : (
                              <LineSimulation
                                title="Main"
                                value={`${(
                                  result.current /
                                  (result.length / 100)
                                ).toFixed(1)} %`}
                              />
                            )}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>

                  {!!onCancelWaterBalance && (
                    <Button
                      variant="outlined"
                      className={styled.cancelBtn}
                      onClick={onCancel}
                      disabled={isCanceling}
                      isLoading={isCanceling}
                    >
                      <FormattedMessage {...commonMessages.cancel} />
                    </Button>
                  )}
                </>
              )}

              {isFailedSimulation && (
                <SpanError errorMessage={result?.exception ?? ""} />
              )}
            </div>
            {tablesValues != null && <div className={styled.simulationResultInfo}>
              <table className={styled.dataTable}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage {...BatchesResultMessages.farmingZoneFraction} />
                    </th>
                    <th>
                      <FormattedMessage {...BatchesResultMessages.groundCoverageRatio} />
                    </th>
                    <th>
                      <FormattedMessage {...BatchesResultMessages.installedCapacity} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tablesValues.ZONE_CULTIVABLE}</td>
                    <td>{tablesValues.TAUX_DE_COUVERTURE}</td>
                    <td>{tablesValues.PUISSANCE_INSTALLEE}</td>
                  </tr>
                </tbody>
              </table>

              <table className={styled.dataTable}>
                <thead>
                  <tr>
                    <th>
                    </th>
                    <th>
                      Under Panels Zone
                    </th>
                    <th>
                      Between Panels Zone
                    </th>
                    <th>
                      Agri PV Zone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Irradiance (%)</td>
                    <td>{Math.round((result.result_data[0].etp_under_panels * 100 / result.result_data[0].etp_ref) * 100) / 100}%</td>
                    <td>{Math.round((result.result_data[0].etp_between_panels * 100 / result.result_data[0].etp_ref) * 100) / 100}%</td>
                    <td>{Math.round((result.result_data[0].etp_agripv * 100 / result.result_data[0].etp_ref) * 100) / 100}%</td>
                  </tr>
                  <tr>
                    <td>ETP (%)</td>
                    <td>{Math.round((result.result_data[0].area_under_panels * 100 / result.result_data[0].power_ref) * 100) / 100}%</td>
                    <td>{Math.round((result.result_data[0].area_between_panels * 100 / result.result_data[0].power_ref) * 100) / 100}%</td>
                    <td>{Math.round((result.result_data[0].area_agrilpv * 100 / result.result_data[0].power_ref) * 100) / 100}%</td>
                  </tr>
                </tbody>
              </table>
            </div>}
          </div>


        </div>
      </div >

      {isSuccessSimulation && (
        <WaterBalanceResultMenu result={result} />
      )
      }
    </>
  );
};

export default WaterBalanceResultBody;

